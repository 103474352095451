import {useState} from 'react'
import { Helmet } from 'react-helmet';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { DragArea, DragObject } from '../../../components/DragAndDrop';
import FileUpload from '../../../components/FileUpload';
import Form, {Input, DatePicker} from '../../../components/Form'
import Forbidden from '../../Forbidden';

function EditAlbum({nsp}) {
	const {t, user, site} = nsp;
	const {id, uid} = useParams();
	const [uploads, setUploads] = useState([]);
	const [albumId, setAlbumId] = useState(uid);
	const [values, setValues] = useState(false);
	const [isSendingDeleteRequest, setIsSendingDeleteRequest] = useState(false);
	const history = useHistory();

	const initialPages = (data) => {
		if (data && data.data && data.data.pageDetails) {
			setUploads(data.data.pageDetails);
		}
	}

	let comic;
	if (user.comics && Array.isArray(user.comics)) {
		const checkUserArray = user.comics.filter((el) => el.id == id);
		if (checkUserArray.length) {
			comic = checkUserArray[0];
		}
	}

	if (!comic) {
		return <Forbidden nsp={nsp} />
	}

	const albumSubmitted = (data) => {
		history.push(`/n/${t['/ME']}/${t['/COMICS']}/${id}/${t['/ALBUMS']}/`);
	}

	// Delete single page

	const deleteUpdate = async (updateId) => {
		setIsSendingDeleteRequest(true);
		const res = await fetch(`${site.api}updates/${id}/${updateId}/`, {
			method: 'DELETE',
			mode: 'cors',
			credentials: 'include',
			headers: {'Content-Type':'application/json', 'Accept': 'application/json'},
		})
		const resJson = await res.json();
		if (resJson.error) {
			// Error handling
		}
		else {
			setUploads(uploads.filter(el => el.id !== updateId));
		}
		setIsSendingDeleteRequest(false);
	}

	const confirmDelete = (theUpdate) => {
		let confirm = window.confirm(t['DELETE_ALBUM_PAGE_CONFIRM']);
		if (confirm) {
			deleteUpdate(theUpdate);
		}
	}

	return (
		<div>
			<Helmet>
				<title>{`${t['EDIT_ALBUM']} :: ${t['PAGE_TITLE']}`}</title>
			</Helmet>
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
				{" > "}
				<Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', comic.id)}>{comic.name}</Link>
				{" > "}
				<Link to={`/n/${t['/ME']}/${t['/COMICS']}/${id}/${t['/ALBUMS']}/`}>{t['ALL_ALBUMS']}</Link>
				{" > "}
			</nav>
			<h2>{t['EDIT_ALBUM']}</h2>

			<h3>{t['ALBUM_METADATA']}</h3>
			<Form
				nsp={nsp}
				submitText={t['SAVE_CHANGES']}
				formName="editAlbum"
				setFormValues={setValues}
				setInitialValues={initialPages}
				onSuccess={albumSubmitted}
				mode="edit"
				action={`${site.api}updates/${id}/${albumId}/`}
				>
				<Input type="hidden" name="pages" value={uploads.map(el => el.id).join()} />
				<Input name="title" type="text" label={t['TITLE']} mandatory={true} />
				<Input name="text" type="editor" label={t['DESCRIPTION']}/>
				<DatePicker name="pubtime" label={t['PUBLISH_TIME']} />
				<Input type="select" name="published" label={t['VISIBILITY']}>
					<option value="0">{t['DRAFT']}</option>
					<option value="1">{t['PUBLISHED']}</option>
				</Input>
			</Form>

			<h3>{t['ALBUM_REARRANGE_PAGES']}</h3>
			<p>{t['ALBUM_REARRANGE_DESCRIPTION']}</p>
			<section className="album-pages">
				<DragArea uploads={uploads} setUploads={setUploads}>
				{uploads.map((el, index) => {
					return <DragObject
						type="article"
						myClassName="page"
						key={index} 
						elementId={el.id}
					>
						<p>{t['PAGE_X'].replace('{n}', (index + 1))}</p>
						<p className="image"><img src={`${site.media}thumbs/${el.image}`} alt={t['THUMB_UPLOAD_ALT_TEXT'].replace('{filename}', values['title-' + el.id])} /></p>
						<div className="album-page-controls">
						<p className="filename">{el.image}</p>
						{isSendingDeleteRequest ? '' : <p><button className="link" onClick={() => confirmDelete(el.id)}><Icon icon={faTrashAlt} /></button></p>}
						</div>
					</DragObject>

				})}
				</DragArea>
			</section>
			<h3>{t['UPLOAD_MORE_PAGES']}</h3>
			<FileUpload nsp={nsp} comic={id} uploads={uploads} setUploads={setUploads} elementArray={uploads} setElementArray={setUploads} type="ap" albumId={uid} setAlbumId={setAlbumId} />

		</div>
	)
}

export default EditAlbum;	