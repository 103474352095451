function FormattedDate({date, nsp,  noTimeTag, dateOnly}) {
	const {t} = nsp;
	let timestring;

	if (!date) {
		return false;
	}

	let dateArr = date.match(/^(\d{4,5})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[0-1])(?: ([01][0-9]|2[0-3]):([0-5][0-9])(?::([0-5][0-9]))?)?$/);

	if (dateArr) {
		if (dateOnly || typeof(dateArr[4]) === 'undefined') {
			date = date.substring(0, 10);
			timestring = t["DATE_FORMAT_SHORT"].replace('{d}', dateArr[3]).replace('{m}', t[`M${dateArr[2]}`]).replace('{Y}', dateArr[1]);
		}
		else {
			timestring = t["DATE_FORMAT"].replace('{d}', dateArr[3]).replace('{m}', t[`M${dateArr[2]}`]).replace('{Y}', dateArr[1]).replace('{H}', dateArr[4]).replace('{i}', dateArr[5]);
	
		}
	
		if (noTimeTag) {
			return timestring;
		}
	
		return (
			<time dateTime={date}>{timestring}</time>
		)
	}
	return '';
}

export default FormattedDate
