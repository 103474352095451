import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faUserCircle, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import UserMenu from './UserMenu';

function HeaderButtons({nsp, pageHasSearchbar}) {
	const {t, showSearch, toggleSearch, user, showMenu, toggleMenu, changeOverlay, showUserMenu, toggleShowUserMenu}  = nsp;
	return (
		<nav className="header-buttons" aria-label={t['SECONDARY_MENU']}>
			{pageHasSearchbar ? <button className="mobile-only" aria-label={t['SEARCH']} onClick={() => toggleSearch( showSearch ? false : true )}>
				<Icon icon={faSearch} />
			</button> : null}
			{(user && user.id) ? <button onClick={() => toggleShowUserMenu(showUserMenu ? false : true)} aria-label={t['USER_MENU']} className="menu-avatar-button" id="menuAvatarButton">
				<p>{user.name}</p> 
				{user.avatar ? <img src={user.avatar} alt={t['MY_SETTINGS']} className="menu-avatar" /> : <Icon icon={faUserCircle} />}
			</button> : <button onClick={() => changeOverlay("log-in")} aria-label={t['LOG_IN']}>
				<Icon icon={faSignInAlt} />
			</button>}
			<button className="mobile-only" id="mainMenuButton" onClick={() => toggleMenu( showMenu ? false : true )} aria-label={t['MAIN_MENU']}>
				<Icon icon={faBars} />
			</button>
			<UserMenu nsp={nsp} />
		</nav>
	)
}

export default HeaderButtons
