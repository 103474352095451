function BlockedComic({nsp}) {
	const {t} = nsp;
	return (
		<main className="main">
			<h2>{t['COMIC_IS_BLOCKED']}</h2>
			<p>{t['BLOCK_DESCRIPTION']}</p>
			<p>{t['BLOCK_DESCRIPTION_2']}</p>
		</main>
	)
}

export default BlockedComic
