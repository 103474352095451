import Forbidden from "../../Forbidden";
import {Link, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';

function ComicDashboard({nsp}) {
	const {user, t} = nsp;
	const {id} = useParams();
	let comic;
	if (user.comics && Array.isArray(user.comics)) {
		const checkUserArray = user.comics.filter((el) => el.id == id);
		if (checkUserArray.length) {
			comic = checkUserArray[0];
		}
	}

	if (comic) {
		return (
			<div>
				<Helmet>
					<title>{t['DASHBOARD_FOR_COMIC'].replace('{comic}', comic.name)} :: {t['PAGE_TITLE']}</title>
				</Helmet>
				<nav className="breadcrumbs">
					<Link to="/">{t['PAGE_TITLE']}</Link>
					{" > "}
					<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
					{" > "}
					<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
					{" > "}
				</nav>
				<h2>{comic.name}</h2>
				<p><Link to={`/${comic.url}/`} className="button">{t['SHOW_COMIC']}</Link></p>
				<nav className="dashboard">
					<ul>
						<li><Link to={`/n/${t['/ME']}/${t['/COMICS']}/${comic.id}/${t['/STRIPS']}/${t['/NEW']}/`}>{t['NEW_STRIP']}</Link></li>
						<li><Link to={`/n/${t['/ME']}/${t['/COMICS']}/${comic.id}/${t['/ALBUMS']}/${t['/NEW']}/`}>{t['NEW_ALBUM']}</Link></li>
						<li><Link to={t['LINK_NEW_BLOG_POST'].replace('{comic}', comic.id)}>{t['NEW_BLOG_POST']}</Link></li>
					</ul>
					<ul>
						<li><Link to={`/n/${t['/ME']}/${t['/COMICS']}/${comic.id}/${t['/STRIPS']}/`}>{t['ALL_STRIPS']}</Link></li>
						<li><Link to={`/n/${t['/ME']}/${t['/COMICS']}/${comic.id}/${t['/ALBUMS']}/`}>{t['ALL_ALBUMS']}</Link></li>
						<li><Link to={`/n/${t['/ME']}/${t['/COMICS']}/${comic.id}/${t['/BLOG']}/`}>{t['ALL_BLOG_POSTS']}</Link></li>
						<li><Link to={`/n/${t['/ME']}/${t['/COMICS']}/${comic.id}/${t['/COMMENTS']}/`}>{t['ALL_COMMENTS']}</Link></li>
					</ul>
					<ul>
						<li><Link to={t['LINK_EDIT_COMIC'].replace('{comic}', comic.id)}>{t['GENERAL_SETTINGS']}</Link></li>
						<li><Link to={`/n/${t['/ME']}/${t['/COMICS']}/${comic.id}/${t['/APPEARANCE']}/`}>{t['APPEARANCE_SETTINGS']}</Link></li>
						<li><Link to={t['LINK_EDIT_CREATORS'].replace('{comic}', comic.id)}>{t['CREATORS_AND_EDITORS']}</Link></li>
{/*						<li><Link to={`/n/${t['/ME']}/${t['/COMICS']}/${comic.id}/${t['/INTEGRATIONS']}/`}>{t['INTEGRATIONS']}</Link></li> */}
					</ul>
					<ul>
						<li><Link to={t['LINK_DELETE_COMIC'].replace('{comic}', comic.id)}>{t['DELETE_COMIC']}</Link></li>
					</ul>
				</nav>				
			</div>
		)
	
	}
	else {
		return (<Forbidden nsp={nsp} />)
	}
}

export default ComicDashboard
