import {useState} from 'react';
import { Helmet } from 'react-helmet';
import {Link} from 'react-router-dom';
import Form from '../../components/Form';

function Settings({nsp}) {
	const {t, user, changeUser, site} = nsp;
	const [updated, setUpdated] = useState(false);
	const [emailChanged, setEmailChanged] = useState(false);

	const onSuccess = (data) => {
		setUpdated(true);
		if (data.changes && data.changes.email) {
			setEmailChanged(true);
		}
		if (data.changes && data.changes.username) {
			const newUserObject = {...user, name: data.changes.username};
			changeUser(newUserObject);
		}
		setTimeout(() => {
			setUpdated(false);
			setEmailChanged(false);
		}, 3000);
	}

	let pageContent;
	if (user && user.id) {
		pageContent = <Form
		nsp={nsp}
		formName="userSettings"
		action={`${site.api}users/${user.id}/`}
		submitText={t["EDIT_PROFILE"]}
		mode="edit"
		onSuccess={onSuccess}
		fields={[
			{
				name: "username",
				label: t['PREFERRED_USERNAME'],
				mandatory: true,
				validate: 'notemail',
			},
			{
				name: "realname",
				label: t['REAL_NAME'],
			},
			{
				name: "email",
				label: t['EMAIL_ADDRESS'],
				mandatory: true,
				validate: 'email',
			},
			{
				name: "profile",
				type: "textarea",
				label: t['PROFILE_TEXT'],
			},
		]} 
	 />;
	}
	else {
		pageContent = <p>{t['NOT_AUTHORIZED']}</p>;
	}
	
	return (
		<div>
			<Helmet>
				<title>{`${t['MY_SETTINGS']} : ${t['PAGE_TITLE']}`}</title>	
			</Helmet>
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
			</nav>
			<h2>{t['MY_SETTINGS']}</h2>
			{updated ? <p className="settings-saved">{t["SETTINGS_SAVED"]} {emailChanged ? t['EMAIL_CHANGE_MUST_BE_CONFIRMED'] : ""}</p> : ""}
			{pageContent}
		</div>
	)
}

export default Settings
