import {useState} from 'react'

function ChangeLanguage({nsp}) {
	const {t, language, setLanguage, changeOverlay} = nsp;

	const [selectedLang, setSelectedLang] = useState(language);

	const languages = ['en', 'nb', 'nn'];

	return (
		<div>
			<h2>{t['SELECT_LANGUAGE']}</h2>
			{languages.map(lang => <p key={lang}><input type="radio" name="language" value={lang} id={'lang-radio-' + lang } checked={selectedLang === lang} onChange={() => setSelectedLang(lang)} /><label htmlFor={'lang-radio-' + lang}> {t['LANG_' + lang.toUpperCase()]}</label></p>)}
			<button type="submit" onClick={() => {
				setLanguage(selectedLang);
				localStorage.setItem('language', selectedLang);
				changeOverlay("");
				
			}}>{t['SAVE']}</button>
			<p className="gdpr-notification">{t['LANGUAGE_GDPR_STUFF']}</p>
		</div>
	)
}

export default ChangeLanguage
