import {useState, useEffect} from 'react'
import Loading from '../components/Loading';

function ComicSubmenu({nsp, theComic, setIsBlocked}) {
	const {t, user, site} = nsp;

	const userId = user.id;
	const comicId = theComic.id;

	const [loaded, setLoaded] = useState(false);
	const [relations, setRelations] = useState([]);

	useEffect(() => {
		const getRelations = async () => {
			const res = await fetch(`${site.api}user-comic-relation/${comicId}/`, {credentials: 'include'});
			const json = await res.json();
			if (json.relations) {
				setRelations(json.relations);
			}
			setLoaded(true);
		}
		getRelations();
	}, [userId, comicId])

	if (!user || !user.id || relations.includes('c') || relations.includes('e')) {
		return null;
	}

	if (!loaded) {
		return <Loading myClassName='comic-submenu-loading' />;
	}

	const relClick = async (type) => {
		setLoaded(false);
		let formData = new FormData();

		if (type === 'remove') {
			formData.append('action', 'remove');
		}
		if (type === 'follow') {
			formData.append('rel', 'f');
		}
		if (type === 'block') {
			formData.append('rel', 'b');
		}

		const res = await fetch(site.api + 'user-comic-relation/' + comicId + '/', {
			method: 'POST',
			body: formData,
			credentials: 'include',
			mode: 'cors',
		});
		const json = await res.json();
		if (json.relations) {
			setRelations(json.relations);
		}
		setLoaded(true);
	}

	relations.includes('b') ? setIsBlocked(true) : setIsBlocked(false); 

	if (relations.includes('b')) {
		return <nav className="comic-submenu"><p className="blocked-comic"><strong>{t['THIS_COMIC_IS_BLOCKED']}</strong> <button className="link" onClick={() => relClick('remove')}>{t['UNBLOCK']}</button></p></nav>
	}
	else if (relations.includes('f')) {
		return <nav className="comic-submenu"><p><button className="button" onClick={() => relClick('remove')}>{t['UNFOLLOW']}</button></p></nav>
		
	}

return <nav className="comic-submenu">
		<p><button className="button" onClick={() => relClick('follow')}>{t['FOLLOW']}</button></p>
		<p><button className="link" onClick={() => relClick('block')}>{t['BLOCK']}</button></p>
	</nav>
}

export default ComicSubmenu
