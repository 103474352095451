import React from 'react'
import {Route, Switch} from 'react-router-dom';
import Albums from './Albums';
import Blog from './Blog';
import BlogPost from './BlogPost';
import ComicFrontpage from './ComicFrontpage';
import Strip from './Strip';

function ComicMain({nsp, theComic}) {
	return (
		<main className="main">
			<Switch>
				<Route path={[`/${theComic.url}/blog/:slug/`, `/${theComic.url}/blogg/:slug/`]}>
					<BlogPost nsp={nsp} theComic={theComic} />
				</Route>
				<Route path={[`/${theComic.url}/blog/`, `/${theComic.url}/blogg/`]}>
					<Blog nsp={nsp} theComic={theComic} />
				</Route>
				<Route path={[`/${theComic.url}/strips/:slug/`, `/${theComic.url}/striper/:slug/`]}>
					<Strip nsp={nsp} theComic={theComic} />
				</Route>
				<Route path={[`/${theComic.url}/strips/`, `/${theComic.url}/striper/`]}>
					<Strip nsp={nsp} theComic={theComic} />
				</Route>
				<Route path={[`/${theComic.url}/album/:slug/`, `/${theComic.url}/album/`]}>
					<Albums nsp={nsp} theComic={theComic} />
				</Route>
				<Route path={[`/${theComic.url}/`, `/${theComic.url}/`]}>
					<ComicFrontpage nsp={nsp} theComic={theComic} />
				</Route>
			</Switch>
		</main>
	)
}

export default ComicMain;