import { Link } from "react-router-dom";
import Updates from "../components/Updates";

function ComicFrontpage({nsp, theComic}) {
	const {t} = nsp;
	return (
		<div>
			{theComic.count_c ? <Updates nsp={nsp} count="1" comic={theComic.id} type="c" fields={['linkimage', 'navigation']} /> : ''}
			{theComic.count_a ? <div className="latest-albums"><Updates nsp={nsp} count="6" comic={theComic.id} type="a" fields={['h3', 'linkimage']} className="album-shelf" />{theComic.count_a > 6 ? <p className="button-box"><Link to={`/${theComic.url}/${t['/ALBUMS']}/`} className="button">{t['ALL_ALBUMS']}</Link></p> : null}</div> : ''}
			{theComic.count_b ? <section className="grey-box"><Updates nsp={nsp} count="1" comic={theComic.id} type="b" fields={['h3', 'abstract']} /></section> : ''}
		</div>
	)
}

export default ComicFrontpage
