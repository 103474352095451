import {useState, useEffect} from 'react';
import site from './site';
import { Helmet } from 'react-helmet';
import { Switch, Route, useLocation } from 'react-router';
import ComicWrapper from './comic/ComicWrapper';
import Overlay from './layoutElements/Overlay';
import Wrapper from './layoutElements/Wrapper';
import { useMatomo } from '@datapunt/matomo-tracker-react'

function App() {
	const [showMenu, toggleMenu] = useState(false);
	const [overlay, changeOverlay] = useState("");
	const [showUserMenu, toggleShowUserMenu] = useState(false);
	const [darkMode, setDarkMode] = useState(window.localStorage.darkMode ? true : false);
	const [fpRecent, setFpRecent] = useState([]);
	const [fpPopular, setFpPopular] = useState([]);
	const [showSearch, toggleSearch] = useState(false);


	const localLanguage = localStorage.getItem('language');
	const [language, setLanguage] = useState(localLanguage && ['en', 'nb', 'nn'].includes(localLanguage) ? localLanguage : 'nn');
	const [mainDesignLoaded, setMainDesignLoaded] = useState(false);
	const [userObjectFetched, setUserObjectFetched] = useState(false);

	const fpCache = {
		recent: fpRecent,
		setRecent: setFpRecent,
		popular: fpPopular, 
		setPopular: setFpPopular,
	};

	const [user, changeUser] = useState(false);

	const { trackPageView, trackEvent, pushInstruction } = useMatomo();

	let location = useLocation();
	useEffect(() => {
		toggleMenu(() => false);
	}, [location, toggleMenu]);

	useEffect(() => {
		pushInstruction('disableCookies');
		trackPageView();
	}, [location]);

	useEffect(() => {
		const getUserObject = async () => {
			let url = site.api + 'log-in/';
			const res = await fetch(url, {credentials: 'include'});
			const json = await res.json();
			if (json && json.user && json.user.id) {
				changeUser(json.user);
			}
			setUserObjectFetched(true);
		}
		getUserObject();
	}, [])

	// Language settings
	const t=require(`./languages/${language}.json`);

	const nsp = {
		showMenu: showMenu,
		t: t,
		site: site,
		toggleMenu: toggleMenu,
		overlay: overlay,
		changeOverlay: changeOverlay,
		user: user,
		changeUser: changeUser,
		showUserMenu: showUserMenu,
		toggleShowUserMenu: toggleShowUserMenu,
		fpCache: fpCache,
		darkMode: darkMode,
		setDarkMode: setDarkMode,
		showSearch: showSearch,
		toggleSearch: toggleSearch,
		language: language,
		setLanguage: setLanguage,
		mainDesignLoaded: mainDesignLoaded,
		setMainDesignLoaded: setMainDesignLoaded,
		userObjectFetched: userObjectFetched,
	}

	return (<>
		{
			darkMode ? <Helmet>
				<body data-theme="dark"></body>
			</Helmet> : ""
		}
			<Switch>
				<Route path="/n/">
					<Wrapper nsp={nsp} />
				</Route>
				<Route path="/:url/">
					<ComicWrapper nsp={nsp} />
				</Route>
				<Route path="/" exact>
					<Wrapper nsp={nsp} pageHasSearchbar={true} />
				</Route>
			</Switch>
			<Overlay nsp={nsp} />
			{site.env === 'development' ? <p className="test-version-text">TESTVERSJON</p>: null}
		</>
	);
}
console.log(site.env);
export default App;
