import { Helmet } from 'react-helmet';
import {Link} from 'react-router-dom';

function MyPage({nsp}) {
	const {t, user} = nsp;
	let userHasComics = (user && user.comics && Array.isArray(user.comics) && user.comics.length > 0) ? true : false;
	return (
		<div>
			<Helmet>
				<title>{t['MY_PAGE']} :: {t['PAGE_TITLE']}</title>
			</Helmet>
			<nav className="breadcrumbs">
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
			</nav>
			<h2>{t['MY_PAGE']}</h2>
			<ul>
				<li><Link to={t['LINK_MY_COMICS_NEW']}>{t['CREATE_NEW_COMIC']}</Link></li>
				{userHasComics ? <li><Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link></li> : ""}
				<li><Link to={t['LINK_ME_SETTINGS']}>{t['SETTINGS']}</Link></li>
				<li><Link to={`/n/${t['/ME']}/${t['/FAVS']}/`}>{t['COMICS_I_FOLLOW']}</Link></li>
				<li><Link to={`/n/${t['/ME']}/${t['/BLOCKED']}/`}>{t['BLOCKED_COMICS']}</Link></li>
				<li><Link to={t['LINK_ME_LOG_OUT']}>{t['LOG_OUT']}</Link></li>
			</ul>
		</div>
	)
}

export default MyPage
