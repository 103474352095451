import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function Loading({tag, myClassName}) {
	let TagType = 'p';
	if (tag) {
		TagType = tag;
	}

	if (tag === 'tbody') {
		return (
			<tbody className={`loading${myClassName ? ' ' + myClassName : ''}`}><tr><td><Icon icon={faSpinner} /></td></tr></tbody>
		)
	
	}

	return (
		<TagType className={`loading${myClassName ? ' ' + myClassName : ''}`}><Icon icon={faSpinner} /></TagType>
	)
}

export default Loading
