import {useState} from 'react';
import {Redirect, useParams, Link} from 'react-router-dom';
import Form from '../../../components/Form';
import Forbidden from '../../Forbidden';
import {Helmet} from 'react-helmet';

function DeleteComic({nsp}) {
	const {t, user, changeUser, site} = nsp;
	const [deleted, setDeleted] = useState(false);

	const {id} = useParams();
	let comic;
	if (user.comics && Array.isArray(user.comics)) {
		const checkUserArray = user.comics.filter((el) => el.id == id);
		if (checkUserArray.length) {
			comic = checkUserArray[0];
		}
	}

	const onSuccess = (data)  => {
		if (user && Array.isArray(user.comics)) {
			const newUserComics = user.comics.filter((el) => el.id != data.comic);
			const newUserObject = {...user, comics: newUserComics};
			changeUser(newUserObject);
		}
		setDeleted(true);
	}

	if (deleted) {
		return (
			<Redirect push to={t['LINK_MY_COMICS']} />
		)
	}

	else if (comic) {
		return (
			<div>
				<Helmet>
					<title>{`${t['DELETE_COMIC']} :: ${t['PAGE_TITLE']}`}</title>
				</Helmet>
				<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
					<Link to="/">{t['PAGE_TITLE']}</Link>
					{" > "}
					<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
					{" > "}
					<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
					{" > "}
					<Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', comic.id)}>{comic.name}</Link>
					{" > "}
				</nav>
				<h2>{t['DELETE_COMIC']}</h2>
				<p>{t['PLEASE_CONFIRM_DELETION'].replace('{comic}', comic.name)}</p>
				<Form
					formName="deleteComic"
					submitText={t['CONFIRM_PASSWORD_AND_DELETE']}
					action={`${site.api}comics/${comic.id}/`}
					mode="delete"
					nsp={nsp}
					onSuccess={onSuccess}
					fields={[
						{
							type: "password",
							label: t['PASSWORD'],
							mandatory: "true",
							name: "password",
						}
					]}

				/>
				<p><Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', comic.id)}>{t['RETURN_TO_DASHBOARD']}</Link></p>
			</div>
		)
	}
	else {
		return(<Forbidden nsp={nsp} />)
	}
}

export default DeleteComic;