import {useState, useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useParams } from 'react-router-dom';
import NotFound from './NotFound';
import {Helmet} from 'react-helmet';
import Loading from '../components/Loading';
import OptOutButton from '../components/OptOutButton';

function About({nsp}) {
	const {t, language} = nsp;
	const [text, setText] = useState('');
	const [title, setTitle] = useState(t['ABOUT_NETTSERIER']);
	const [loaded, setLoaded] = useState(false);
	let {page} = useParams();

	const validPages = ['about', 'privacy', 'cookies', 'contact'];
	const norwegianPages = {om: 'about', personvern: 'privacy', informasjonskapslar: 'cookies', kontakt: 'contact'};
	if (page && !validPages.includes(page)) {
		if (norwegianPages[page]) {
			page = norwegianPages[page];
		}
		else {
			page = '404';
		}
	}

	useEffect(() => {
		const getText = async () => {
			if (!page || page === '404') {
				return;
			}
			const res = await fetch(`/markdown_files/${page}_${language}.md`);
			const content = await res.text();
			if (content.startsWith('## ')) {
				setText(content);
				setTitle(content.match(/^## (.*)/)[1]);
			}
		};
		setLoaded(false);
		setText('');
		getText();
		setLoaded(true);
	}, [page, language]);

	if (page === '404' || (loaded && !title)) {
		return <NotFound nsp={nsp} />
	}

	let pageContent;
	const rootUrl = `/n/${t['/HELP']}/`;

	if (!loaded) {
		pageContent = <Loading nsp={nsp} />;
	}

	if (!page) {
		pageContent = <>
			<p className="breadcrumbs">
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
			</p>
			<h2>{t['HELP']}</h2>
			<h3>{t['WHAT_IS_NETTSERIER']}</h3>
			<ul>
				<li><Link to={rootUrl + t['/ABOUT'] + "/"}>{t['ABOUT_NETTSERIER']}</Link></li>
				<li><Link to={rootUrl + t['/CONTACT'] + "/"}>{t['CONTACT_US']}</Link></li>
			</ul>
			<h3>{t['GDPR_STUFF']}</h3>
			<ul>
			<li><Link to={rootUrl + t['/PRIVACY'] + "/"}>{t['PRIVACY_POLICY']}</Link></li>
				<li><Link to={rootUrl + t['/COOKIES'] + "/"}>{t['COOKIES']}</Link></li>
			</ul>
		</>;
	}
	else {
		pageContent = <>
			<p className="breadcrumbs">
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={`/n/${t['/HELP']}/`}>{t['HELP']}</Link>
				{" > "}
			</p>
			<ReactMarkdown>{text}</ReactMarkdown>
			{page === 'privacy' && text ? <OptOutButton nsp={nsp} /> : ''}
		</>;
	}

	return (
		<div className="about-page">
			<Helmet><title>{`${title} :: ${t['PAGE_TITLE']}`}</title></Helmet>
			{pageContent}			
		</div>
	)
}

export default About
