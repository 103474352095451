import {useState} from 'react'
import { Helmet } from 'react-helmet';
import { Link, useParams, useHistory } from 'react-router-dom';
import { DragArea, DragObject } from '../../../components/DragAndDrop';
import FileUpload from '../../../components/FileUpload';
import Form, {DatePicker, Input} from '../../../components/Form'
import Forbidden from '../../Forbidden';

function NewStrip({nsp}) {
	const {t, user, site} = nsp;
	const {id} = useParams();
	const [uploads, setUploads] = useState([]);
	const [values, setValues] = useState(false);
	const history = useHistory();
	let comic;
	if (user.comics && Array.isArray(user.comics)) {
		const checkUserArray = user.comics.filter((el) => el.id == id);
		if (checkUserArray.length) {
			comic = checkUserArray[0];
		}
	}

	if (!comic) {
		return <Forbidden nsp={nsp} />
	}


	let scheduleDays = [];
	for (let i = 0; i < 7; i++) {
		if (values['weekday-' + i]) {
			scheduleDays.push(i);
		}
	}
	if (!scheduleDays.length) {
		scheduleDays = [0, 1, 2, 3, 4, 5, 6];
	}

	let startDate = new Date('2021-08-16T02:00:00');
	const daysFromStart = [];

	let firstDayOfWeek = startDate.getDay();
	for (let i = 0; i < 7; i++) {
		if (scheduleDays.includes((firstDayOfWeek + i) % 7)) {
			daysFromStart.push(i);
		}
	}

	let weeklyUpdates = daysFromStart.length;

	let scheduleTimeOfDay = /^([01][0-9]|2[0-4]):[0-5][0-9](:[0-5][0-9])?$/.test(values['scheduler-set-time']) ? values['scheduler-set-time'] : '00:00';

	const dateFromKey = (number) => {
		const returnDate = new Date(startDate.getTime());
		returnDate.setDate(startDate.getDate() + (Math.floor(number / weeklyUpdates) * 7 + daysFromStart[number % weeklyUpdates]));
		return returnDate;
	}
	
	const stripFormSubmitted = (data) => {
		history.push(`/n/${t['/ME']}/${t['/COMICS']}/${id}/${t['/STRIPS']}/`);
	}

	return (
		<div>
			<Helmet>
				<title>{`${t['NEW_STRIP']} :: ${t['PAGE_TITLE']}`}</title>
			</Helmet>
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
				{" > "}
				<Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', comic.id)}>{comic.name}</Link>
				{" > "}
				<Link to={`/n/${t['/ME']}/${t['/COMICS']}/${id}/${t['/STRIPS']}/`}>{t['ALL_STRIPS']}</Link>
				{" > "}
			</nav>
			<h2>{t['NEW_STRIP']}</h2>

			<FileUpload nsp={nsp} comic={id} elementArray={uploads} setElementArray={setUploads} type="c" />

			{uploads.length ?
				<Form
				nsp={nsp}
				submitText={t['PUBLISH_STRIPS']}
				draftText={t['SAVE_AS_DRAFT']}
				formName="editUploadedFiles"
				setFormValues={setValues}
				onSuccess={stripFormSubmitted}
				mode="edit"
				doNotFetchInitials={true}
				action={site.api + 'updates/' + id + '/'}
			>
				<Input type="hidden" name="rows" value={uploads.map(el => el.id).join()} />
				<p>{t['UPLOAD_SAVED_IN_DRAFTS']}</p>
{/*				<Input type="checkbox" name="use-scheduler" label={t['USE_SCHEDULER']} className="scheduler-checkbox" />
				<div className={values['use-scheduler'] ? 'scheduler-weekdays' : 'invisible'}>
					<p className="label">{t['PUBLISH_ON_THESE_DAYS']}</p>
					<Input type="checkbox" name="weekday-1" label={t['D1']} />
					<Input type="checkbox" name="weekday-2" label={t['D2']} />
					<Input type="checkbox" name="weekday-3" label={t['D3']} />
					<Input type="checkbox" name="weekday-4" label={t['D4']} />
					<Input type="checkbox" name="weekday-5" label={t['D5']} />
					<Input type="checkbox" name="weekday-6" label={t['D6']} />
					<Input type="checkbox" name="weekday-0" label={t['D0']} />
				</div>
				<Input type="time" name="scheduler-set-time" label={t['PUBLISH_AT_THIS_TIME']} containerClass={values['use-scheduler'] ? '' : 'invisible'} />
*/}
				<DragArea uploads={uploads} setUploads={setUploads}>
				{uploads.map((el, index) => {
					const sDate = dateFromKey(index);
					return <DragObject
						type="article"
						myClassName="edit-multiple-strips"
						key={index} 
						elementId={el.id}
					>
					<p className="image"><img src={`${site.media}thumbs/${el.image}`} alt={t['THUMB_UPLOAD_ALT_TEXT'].replace('{filename}', values['title-' + el.id])} /></p>
					<div className="settings">
						<h3>{values['title-' + el.id] ? values['title-' + el.id] : el.image}</h3>
						<Input name={`title-${el.id}`} label={t['TITLE']} />
						<Input name={`text-${el.id}`} label={t['DESCRIPTION']} type="editor" />
						<div className={values['use-scheduler'] ? "invisible" : ""}>
							<DatePicker name={`pubtime-${el.id}`} label={t['PUBLISH_TIME']} />
						</div>
						<div className={values['use-scheduler'] ? "" : "invisible"}>
						<p>Publiseres {sDate.getDate()}.{sDate.getMonth() + 1}.{sDate.getFullYear()} klokka {scheduleTimeOfDay}</p>
						</div>
					</div>
				</DragObject>

				})}
				</DragArea>
			</Form> : ""}
		</div>
	)
}

export default NewStrip;