import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import MenuButtons from './MenuButtons';

function MainMenu({nsp}) {
	const {showMenu, t, toggleMenu} = nsp;
	let location = useLocation();
	useEffect(() => {
		window.addEventListener('click', function(e){   
			if (document.getElementById('mainMenu') &&
			 !document.getElementById('mainMenu').contains(e.target) &&
			 (!document.getElementById('mainMenuButton') ||
			  !document.getElementById('mainMenuButton').contains(e.target))){
				toggleMenu(false);
			}
		  });
	}, [location, toggleMenu]);

	return (
		<nav className={`main-menu${showMenu ? " visible" : ""}`} id="mainMenu" aria-label={t['MAIN_MENU']}>
			<ul>
				<li><NavLink exact to="/">{t['FRONTPAGE']}</NavLink></li>
				<li><NavLink to={`/n/${t['/DAILY']}/`}>{t['TODAYS_COMICS']}</NavLink></li>
				<li><NavLink to={`/n/${t['/COMICS']}/`}>{t['ALL_COMICS']}</NavLink></li>
				<li><NavLink to={`/n/${t['/BLOG']}/`}>{t['BLOG']}</NavLink></li>
				<li><NavLink to={`/n/${t['/SEARCH']}/`}>{t['SEARCH']}</NavLink></li>
				<li><NavLink to={`/n/${t['/HELP']}/`}>{t['HELP']}</NavLink></li>
				<MenuButtons nsp={nsp} />
			</ul>
		</nav>
	);
}

export default MainMenu;