import { Switch, Route, Link } from 'react-router-dom';
import logo from '../media/logo.png';
import darkLogo from '../media/logo-dark.png';
import HeaderButtons from './HeaderButtons';

function Header({nsp, pageHasSearchbar}) {
	const {t, darkMode}  = nsp;
	return (
		<header>
			<h1><Switch>
				<Route exact path="/">
					<img src={darkMode ? darkLogo : logo} className="logo" alt={t['PAGE_TITLE']} />
				</Route>
				<Route path="/">
					<Link to="/"><img src={darkMode ? darkLogo : logo} className="logo" alt={t['PAGE_TITLE']} /></Link>
				</Route>
			</Switch></h1>
			<HeaderButtons nsp={nsp} pageHasSearchbar={pageHasSearchbar} />
		</header>
	)
}
export default Header;