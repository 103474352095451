import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet';


function ConfirmEmail({nsp}) {
	const {user, changeUser, site} = nsp;
	const {token} = useParams();
	const [pageContent, updatePageContent] = useState(<p><span className="loading"><Icon icon={faSpinner} /></span> Forsøker å validere e-postadresse ...</p>); 

	useEffect(() => {
		const validateEmail = async () => {
			let formData = new FormData();
			formData.append('token', token);

			try {
				const res = await fetch(site.api + 'confirm-email/', {
					method: 'POST',
					credentials: 'include',
					body: formData,
				});
				const json = await res.json();
				if (json.error) {
					updatePageContent(<p className="error-msg"><Icon icon={faExclamationTriangle} className="error-icon" />{`Ugyldig e-posttoken.`}</p>);
				}
				else {
					// Are we logged in in this browser?
					if (user) {
						// Are we logged in with the same user credentials as the cookie on the server?
						if (json.user && user.id == json.user.id ) {
							const hasLevelChanged = (!user.level && json.user.level);
							changeUser(() => json.user);
							hasLevelChanged ? updatePageContent(<p>E-postadressa di er stadfesta, og du har no tilgong til å publisere innhald på Nettserier.no. (Dersom du er innlogga på andre einingar eller i andre nettlesarar enn denne, må du logge deg inn på nytt for å få desse tilgongane.)</p>) : updatePageContent(<p>E-postadressa di er stadfesta.</p>);
						}
						else {
							updatePageContent(<p>E-postadressa er stadfesta.</p>);							
						}
					}
					else {
						updatePageContent(<p>E-postadressa er stadfesta.</p>);
					}
				}
			} catch (e) {
				console.log(e);
				updatePageContent("Urk!"); 
			}
		};
		if (token) {
			validateEmail();
		}
		else {
			updatePageContent(<p>Kunne ikkje stadfeste e-postadressa fordi lenkja er ugyldig: Stadfestingstoken manglar.</p>)
		}
	}, []);

	return (
		<div>
			<Helmet>
				<title>Stadfest e-postadresse : Nettserier.no</title>
			</Helmet>
			<h2>Stadfester e-postadresse</h2>
			{pageContent}
		</div>
	)
}

export default ConfirmEmail;
