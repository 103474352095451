import {useEffect} from 'react'
import {NavLink, useLocation} from 'react-router-dom';
import MenuButtons from '../layoutElements/MenuButtons';

function ComicMenu({nsp, theComic}) {
	const {t, showMenu, toggleMenu} = nsp;

	let location = useLocation();
	useEffect(() => {
		window.addEventListener('click', function(e){   
			if (document.getElementById('mainMenu') &&
			 !document.getElementById('mainMenu').contains(e.target) &&
			 (!document.getElementById('mainMenuButton') ||
			  !document.getElementById('mainMenuButton').contains(e.target))){
				toggleMenu(false);
			}
		  });
	}, [location, toggleMenu]);


	return (
		<nav className={`main-menu${showMenu ? " visible" : ""}`} id="mainMenu">
			<ul>
				<li><NavLink to={`/${theComic.url}/`} exact>{t['MAIN_PAGE']}</NavLink></li>
				{theComic.count_c ? <li><NavLink to={`/${theComic.url}/${t['/STRIPS']}/`}>{t['STRIPS']}</NavLink></li> : ""}
				{theComic.count_a ? <li><NavLink to={`/${theComic.url}/${t['/ALBUMS']}/`}>{t['ALBUMS']}</NavLink></li> : ""}
				{theComic.count_b ? <li><NavLink to={`/${theComic.url}/${t['/BLOG']}/`}>{t['BLOG']}</NavLink></li> : ""}
				<MenuButtons nsp={nsp} location="comic" />
			</ul>
		</nav>
	)
}

export default ComicMenu
