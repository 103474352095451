import {useEffect} from 'react'
import {Route, Switch, useLocation} from 'react-router-dom'
import LogOut from './LogOut';
import ConfirmEmail from './ConfirmEmail';
import ChangePassword from './ChangePassword';
import Settings from './Settings';
import NewComic from './comics/NewComic';
import MyComics from './comics/MyComics';
import DeleteComic from './comics/DeleteComic';
import ComicDashboard from './comics/ComicDashboard';
import NotFound from '../NotFound';
import EditComic from './comics/EditComic';
import EditCreators from './comics/EditCreators';
import MyPage from './MyPage';
import NewBlogPost from './comics/NewBlogPost';
import BlogSettings from './comics/BlogSettings';
import EditBlogPost from './comics/EditBlogPost';
import NewStrip from './comics/NewStrip';
import StripSettings from './comics/StripSettings';
import NewAlbum from './comics/NewAlbum';
import AlbumSettings from './comics/AlbumSettings';
import Integrations from './comics/Integrations';
import EditStrip from './comics/EditStrip';
import EditAlbum from './comics/EditAlbum';
import ComicComments from './comics/ComicComments';
import Favorites from './Favorites';
import BlockedComics from './BlockedComics';
import Appearance from './comics/Appearance';

function UserMain({nsp}) {
	const {toggleShowUserMenu} = nsp;
	let location = useLocation();
	useEffect(() => {
		toggleShowUserMenu(() => false);
	}, [location, toggleShowUserMenu]);

	return (
		<div>
			<Switch>
				<Route path={["/n/me/confirm/:token", "/n/meg/stadfest/:token"]}>
					<ConfirmEmail nsp={nsp} />
				</Route>
				<Route path={["/n/me/settings/", "/n/meg/innstillingar/"]}>
					<Settings  nsp={nsp} />
				</Route>
				<Route path={["/n/me/password/:token", "/n/meg/passord/:token"]}>
					<ChangePassword nsp={nsp} />
				</Route>
				<Route path={["/n/me/password/", "/n/meg/passord/"]}>
					<ChangePassword nsp={nsp} />
				</Route>
				<Route path={["/n/me/log-out/", "/n/meg/logg-ut/"]}>
					<LogOut nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/new/", "/n/meg/seriar/ny/"]}>
					<NewComic nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/:id/edit", "/n/meg/seriar/:id/rediger/"]}>
					<EditComic nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/:id/creators", "/n/meg/seriar/:id/skaparar/"]}>
					<EditCreators nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/:id/comments", "/n/meg/seriar/:id/kommentarar/"]}>
					<ComicComments nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/:id/integrations/", "/n/meg/seriar/:id/integrasjonar/"]}>
					<Integrations nsp={nsp} />
				</Route>

				<Route path={["/n/me/comics/:id/strips/new/", "/n/meg/seriar/:id/striper/ny/"]}>
					<NewStrip nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/:id/strips/:uid/", "/n/meg/seriar/:id/striper/:uid/"]}>
					<EditStrip nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/:id/strips/", "/n/meg/seriar/:id/striper/"]}>
					<StripSettings nsp={nsp} />
				</Route>

				<Route path={["/n/me/comics/:id/albums/new/", "/n/meg/seriar/:id/album/ny/"]}>
					<NewAlbum nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/:id/albums/:uid/", "/n/meg/seriar/:id/album/:uid/"]}>
					<EditAlbum nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/:id/albums/", "/n/meg/seriar/:id/album/"]}>
					<AlbumSettings nsp={nsp} />
				</Route>

				<Route path={["/n/me/comics/:id/blog/new/", "/n/meg/seriar/:id/blogg/ny/"]}>
					<NewBlogPost nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/:id/blog/:uid/", "/n/meg/seriar/:id/blogg/:uid/"]}>
					<EditBlogPost nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/:id/blog/", "/n/meg/seriar/:id/blogg/"]}>
					<BlogSettings nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/:id/appearance", "/n/meg/seriar/:id/utseende/"]}>
					<Appearance nsp={nsp} />
				</Route>

				<Route path={["/n/me/comics/:id/delete", "/n/meg/seriar/:id/slett/"]}>
					<DeleteComic nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/:id/", "/n/meg/seriar/:id/"]}>
					<ComicDashboard nsp={nsp} />
				</Route>
				<Route path={["/n/me/comics/", "/n/meg/seriar/"]}>
					<MyComics nsp={nsp} />
				</Route>
				<Route path={["/n/me/blocked/", "/n/meg/blokkerte/"]}>
					<BlockedComics nsp={nsp} />
				</Route>
				<Route path={["/n/me/favorites/", "/n/meg/favorittar/"]}>
					<Favorites nsp={nsp} />
				</Route>
				<Route path={["/n/me/", "/n/meg/"]}>
					<MyPage nsp={nsp} />
				</Route>
				<Route>
					<NotFound nsp={nsp} />
				</Route>
			</Switch>
		</div>
	)
}

export default UserMain;