import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import Forbidden from '../../Forbidden';
import Updates from '../../../components/Updates';

function BlogSettings({nsp}) {
	const {t, user} = nsp;
	const {id} = useParams();
	let comic;
	if (user.comics && Array.isArray(user.comics)) {
		const checkUserArray = user.comics.filter((el) => el.id == id);
		if (checkUserArray.length) {
			comic = checkUserArray[0];
		}
	}

	if (!comic) {
		return <Forbidden nsp={nsp} />
	}

	return (
		<>
			<Helmet>
				<title>{t['EDIT_BLOG_POSTS'].replace('{comic}', comic.name)} :: {t['PAGE_TITLE']}</title>
			</Helmet>
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
				{" > "}
				<Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', comic.id)}>{comic.name}</Link>
				{" > "}
			</nav>
			<h2>{t['EDIT_BLOG_POSTS'].replace('{comic}', comic.name)}</h2>

			<p><Link className="button inline" to={`/n/${t['/ME']}/${t['/COMICS']}/${id}/${t['/BLOG']}/${t['/NEW']}/`}>{t['NEW_BLOG_POST']}</Link></p>

			<table className="settings-table">
				<thead>
					<tr>
						<th>{t['TITLE']}</th>
						<th>{t['PUBLICATION_TIME']}</th>
						<th>{t['STATUS']}</th>
						<th className="icon-column">{t['SHOW']}</th>
						<th className="icon-column">{t['DELETE']}</th>
					</tr>
				</thead>
				<Updates nsp={nsp} type="b" display="table" context="edit" comic={id} fields={['title', 'pubtime', 'status', 'showLink', 'delete']} count="20" showPagination={true} />
			</table>
		</>
	)
}

export default BlogSettings
