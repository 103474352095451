import {useState, useEffect} from 'react'
import {HashLink} from 'react-router-hash-link';
import FormattedDate from './FormattedDate';
import Loading from './Loading';
import Form, { Input } from './Form';

function Comments({nsp, parent, count, showForm, type, comic}) {
	const {t, user, site} = nsp;

	const [comments, setComments] = useState([]);
	const [loadedComments, setLoadedComments] = useState(false);
	const [newCommentPosted, setNewCommentPosted] = useState(false);
	const [lastLoad, setLastLoad] = useState(0);
	const [pageOffset, setPageOffset] = useState(0);

	const linktype = {a: t['/ALBUMS'], b: t['/BLOG'], c: t['/STRIPS']};
	const getComments = async (nextPage = false) => {
		setLoadedComments(false);
		if (!nextPage) {
			setPageOffset(0);
		}
		let querystring = '';
		if (parent) {
			querystring += (querystring ? '&' : '?');
			querystring += 'parent=' + encodeURIComponent(parent);
		}
		if (count) {
			querystring += (querystring ? '&' : '?');
			querystring += 'count=' + encodeURIComponent(count);
		}
		if (comic) {
			querystring += (querystring ? '&' : '?');
			querystring += 'comic=' + encodeURIComponent(comic);
		}
		if (nextPage) {
			querystring += (querystring ? '&' : '?');
			querystring += 'page=' + encodeURIComponent(pageOffset + 2);
			setPageOffset(pageOffset + 1);
		}
		let url = site.api + 'comments/' + querystring;
		const res = await fetch(url, {credentials: 'include'});
		const json = await res.json();
		if (json.data) {
			setComments(nextPage ? comments.concat(json.data) : json.data);
		}
		setLoadedComments(true);
		setLastLoad(json.data && json.data.length ? json.data.length : 0);
	}

	useEffect(() => {
		getComments();
	}, []);
	
	if (!loadedComments && !comments.length) {
		return <Loading nsp={nsp} />;
	}

	if (type === 'frontpage') {
		return comments.map((el) => <article key={el.id} className="single-comment" id={'comment-' + el.id}>
			<h4 className="update-comicname"><HashLink to={`/${el.comicurl}/${linktype[el.updtype]}/${el.slug}/#comment-${el.id}`}>{el.comicname} &gt; {el.updname ? el.updname : <FormattedDate nsp={nsp} date={el.updtime} dateOnly={true} />} &gt;</HashLink></h4>
			<p>{el.abstract}</p>
			<p className="user">{el.username}</p>
		</article>)
	}

	let commentSection;
	let commentForm;

	if (comments.length) {
		commentSection = <section className="comments">
		{type !== 'all' ? <h4>{t['X_COMMENTS']}</h4> : ''}
		{comments.map((el) => <article key={el.id} className="single-comment" id={'comment-' + el.id}>
			<div className="comment-meta">
				<p className="user">{el.username}</p>
				<p className="timestamp"><HashLink to={'#comment-' + el.id}><FormattedDate date={el.regtime} nsp={nsp} /></HashLink></p>
			</div>
			{type == 'all' ? <p><HashLink to={`/${el.comicurl}/${linktype[el.updtype]}/${el.slug}/#comment-${el.id}`}>{el.comicname} &gt; {el.updname ? el.updname : <FormattedDate nsp={nsp} date={el.updtime} dateOnly={true} />} &gt;</HashLink></p> : ''}
			<div className="user-html" dangerouslySetInnerHTML={{__html: el.text}}></div>
		</article>)}
		{(type === 'all' && lastLoad == count) ? (loadedComments ? <p><button className="button show-more" onClick={() => getComments(true)}>{nsp.t['SHOW_MORE']}</button></p> : <Loading />) : ''}
		</section>
	}

	const commentPosted = (data) => {
		let newComment = data.data;
		newComment.username = user.name;
		setComments([...comments, newComment]);
		setNewCommentPosted(true);
	}

	if (!showForm || newCommentPosted) {
	}
	else if (!user || !user.id) {
		commentForm = <section className="write-comment">
			<h4>{t['LOG_IN_TO_COMMENT']}</h4>
		</section>;
	}
	else if (user.level < 1) {
		commentForm = <section className="write-comment">
			<h4>{t['WRITE_A_COMMENT']}</h4>
			<p>{t['COMMENT_REQUIRES_CONFIRM']}</p>
		</section>;

	}
	else {
		commentForm = <section className="write-comment">
			<h4>{t['WRITE_A_COMMENT']}</h4>
			<Form
				nsp={nsp} 
				formName="newComment"
				submitText={t['POST_COMMENT']}
				onSuccess={commentPosted}
				action={`${site.api}comments/${parent}/`}
			>
				<Input type="editor" name="text" mandatory="true" label={t['YOUR_COMMENT_HERE']} />
			</Form>
		</section>;
	}
	
	return (<>
		{commentSection}
		{commentForm}
	</>
	)
}

export default Comments;