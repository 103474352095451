import {useEffect} from 'react'
import {Link} from 'react-router-dom';

function UserMenu({nsp}) {
	const {t, user, showUserMenu, toggleShowUserMenu} = nsp;
	useEffect(() => {
		window.addEventListener('click', function(e){   
			if (document.getElementById('userMenu') &&
			 !document.getElementById('userMenu').contains(e.target) &&
			 (!document.getElementById('menuAvatarButton') ||
			  !document.getElementById('menuAvatarButton').contains(e.target))){
				toggleShowUserMenu(false);
			}
		  });
	}, []);

	let userHasComics = (user && user.comics && Array.isArray(user.comics) && user.comics.length > 0) ? true : false;

	return (
		<ul className={`user-menu${showUserMenu ? "" : " invisible"}`} id="userMenu">
			<li><Link to={t['LINK_MY_COMICS_NEW']}>{t['CREATE_NEW_COMIC']}</Link></li>
			{userHasComics ? <li><Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link></li> : ""}
			<li><Link to={`/n/${t['/ME']}/${t['/FAVS']}/`}>{t['COMICS_I_FOLLOW']}</Link></li>
			<li><Link to={`/n/${t['/ME']}/${t['/BLOCKED']}/`}>{t['BLOCKED_COMICS']}</Link></li>
			<li><Link to={t['LINK_ME_SETTINGS']}>{t['SETTINGS']}</Link></li>
			<li><Link to={t['LINK_ME_LOG_OUT']}>{t['LOG_OUT']}</Link></li>
		</ul>
	)
}

export default UserMenu;