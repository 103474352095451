import React from 'react'
import Updates from '../components/Updates'
import {Link} from 'react-router-dom';

function Blog({nsp}) {
	const {t} = nsp;
	return (
		<div className="blog-page">
			<p className="breadcrumbs"><Link to="/">{t['PAGE_TITLE'] + " > "}</Link></p>
			<h2>{t['ALL_BLOG_POSTS']}</h2>
			<div className="updates">
				<Updates nsp={nsp} type="b" count="30" fields={["comicname", "h3", "abstract", "pubtime"]} showPagination={true} />
			</div>
		</div>
	)
}

export default Blog
