import accessdenied from '../media/accessdenied.png';
import { Helmet } from 'react-helmet';
import Loading from '../components/Loading';

function Forbidden({nsp}) {
	const {t, userObjectFetched} = nsp;

	if (!userObjectFetched) {
		return <Loading nsp={nsp} />
	}
	return (
		<div>
			<Helmet>
				<title>{`${t['ACCESS_DENIED']} :: ${t['PAGE_TITLE']}`}</title>
			</Helmet>
			<p className="image-container"><img src={accessdenied} alt={t['YOU_DONT_HAVE_ACCESS']} /></p>
			<h2>{t['ACCESS_DENIED']}</h2>
			<p>{t['ERROR_NOT_AUTHORIZED']}</p>
		</div>
	)
}

export default Forbidden
