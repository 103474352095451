import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import './css/nettserier.css';
import App from './App';

const instance = createInstance({
	urlBase: 'https://statistikk.nettserier.no/matomo/',
	siteId: 1,
	linkTracking: false, // optional, default value: true
})

ReactDOM.render(
	<React.StrictMode>
		<MatomoProvider value={instance}>
		<Router>
			<App />
		</Router>
		</MatomoProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
