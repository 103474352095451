import React from 'react';
import {Link} from 'react-router-dom';

function PageFooter({nsp}) {

	const editorName = 'Olaf Moriarty Solstrand';
	const socialLinks = [
/*		{
			'name': 'Patreon',
			'url': 'https://patreon.com/nettserier_no',
		}, */
		{
			'name': 'Facebook',
			'url': 'https://facebook.com/nettserier',
		},
		{
			'name': 'Twitter',
			'url': 'https://twitter.com/nettserier',
		},
	];

	const {t} = nsp;
	return (
		<footer>
			<nav>
				<h3>{t['PAGE_TITLE']}</h3>
				<ul>
					<li><Link to={`/n/${t['/HELP']}/${t['/ABOUT']}/`}>{t['ABOUT_NETTSERIER']}</Link></li>
					<li><Link to={`/n/${t['/HELP']}/${t['/PRIVACY']}/`}>{t['PRIVACY_POLICY']}</Link></li>
					<li><Link to={`/n/${t['/HELP']}/${t['/COOKIES']}/`}>{t['COOKIES']}</Link></li>
				</ul>
			</nav> 
			<nav>
				<h3>{t['FOLLOW_US']}</h3>
				<ul>
					{socialLinks.map((el, index) => <li key={index}><a href={el.url}>{el.name}</a></li>)}
				</ul>
			</nav>
			<section className="copyright">
				<p>{t['ALL_COMICS_COPYRIGHT']}</p>
				<p>{t['EDITOR_IN_CHARGE']} {editorName}</p>
			</section>
		</footer>
	)
}

export default PageFooter
