import Updates from "../components/Updates";
import {Link, useParams} from 'react-router-dom';
import { Helmet } from "react-helmet";

function Albums({nsp, theComic}) {
	const {t} = nsp;
	const {slug} = useParams();
	return (
		<div>
			<Helmet>
				<title>{t['ALL_ALBUMS']} :: {theComic.name}</title>
			</Helmet>
			<p className="breadcrumbs">
				<Link to={`/${theComic.url}/`}>{theComic.name}</Link>
				{" > "}
				{slug ? <><Link to={`/${theComic.url}/${t['/ALBUMS']}/`}>{t['ALL_ALBUMS']}</Link>
				{" > "}</> : ''}
			</p>
			{slug ? '' : <h2>{t['ALL_ALBUMS']}</h2>}
			<Updates nsp={nsp} fields={slug ? ['album', 'comments'] : ['album']} comic={theComic.id} type="a" slug={slug} titleTag={slug ? true : false} />
		</div>
	)
}

export default Albums
