function CreatorList({nsp, creator}) {
	const {t} = nsp;
	if (creator && Array.isArray(creator) && creator.length) {
		return <ul className="creator-list" data-by={t["BY"]}>
			{creator.map((c) => <li key={c.user} data-and={t["AND"]}><span>{c.name}</span></li>)}
		</ul>
	}
	return null;
}

export default CreatorList
