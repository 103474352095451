import { Link } from 'react-router-dom'
import Comments from '../components/Comments'

function AllComments({nsp}) {
	const {t} = nsp;
	return (
		<div>
			<nav className="breadcrumbs">
				<Link to="/">{t['PAGE_TITLE']}</Link>
			</nav>
			<h2>{t['ALL_COMMENTS']}</h2>
			<Comments nsp={nsp} type="all" count="30" />
		</div>
	)
}

export default AllComments
