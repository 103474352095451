import image from '../media/404.png';
import { Helmet } from 'react-helmet';

function NotFound({nsp}) {
	const {t} = nsp;
	return (
		<div>
			<Helmet>
				<title>{`${t['PAGE_NOT_FOUND']} :: ${t['PAGE_TITLE']}`}</title>
			</Helmet>
			<p className="image-container"><img src={image} alt={t['YOU_DONT_HAVE_ACCESS']} /></p>
			<h2>{t['PAGE_NOT_FOUND']}</h2>
			<p>{t['404_PAGE_TEXT']}</p>
		</div>
	)
}

export default NotFound;