import LogInForm from '../pages/LogInForm';
import ForgotPassword from '../pages/user/ForgotPassword'
import UserRegistration from '../components/UserRegistration';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ChangeLanguage from '../pages/ChangeLanguage';

function Overlay({nsp}) {
	const {overlay, changeOverlay} = nsp;
	let content;
	switch(overlay) {
		case "log-in":
			content = <LogInForm nsp={nsp} />;
			break;
		case "userRegistration":
			content = <UserRegistration nsp={nsp} />;
			break;
		case "forgotPassword":
			content = <ForgotPassword nsp={nsp} />;
			break;
		case "selectLanguage":
			content = <ChangeLanguage nsp={nsp} />;
			break;
		default:
			content = "";
	}
	return(
		<div className={`overlay ${overlay === "" ? " invisible": ""}`}>
			<div className="remove-overlay" onClick={() => changeOverlay("")}></div>
			<div className="dialog-box">
				<button className="close-dialog-box" onClick={() => changeOverlay("")}><Icon icon={faTimes} /></button>
				{content}
			</div>
		</div>
	);
}

export default Overlay;