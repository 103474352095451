import {useState} from 'react';
import {useHistory} from 'react-router-dom';

function Searchbar({nsp}) {
	const {t, showSearch} = nsp;
	const [searchText, setSearchText] = useState("");
	const history = useHistory();

	const goToSearchPage = (e) => {
		e.preventDefault();
		history.push('/n/' + t['/SEARCH'] + '/?q=' + encodeURIComponent(searchText))
	}
	return (
		<section className={`searchbox${showSearch ? " visible" : ""}`}>
			<form onSubmit={(e) => goToSearchPage(e)}><input name="q" type="text" value={searchText} onChange={(el) => setSearchText(el.target.value)} className="soketekst" placeholder={t['SEARCH_FOR_A_COMIC']} /></form>
		</section>
	)
}

export default Searchbar;