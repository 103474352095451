import {useState, useEffect} from 'react'
import { useMatomo } from '@datapunt/matomo-tracker-react'

function OptOutButton({nsp}) {
	const {t} = nsp;
	const {pushInstruction} = useMatomo();

	const [opt, setOpt] = useState(false);

	useEffect(() => {
		pushInstruction(function() {
			setOpt(!this.isUserOptedOut())
		});
	
	}, []);

	const clickButton = (el) => {
		if (opt) {
			// User opted out
			pushInstruction('optUserOut');
		}
		else {
			// User opted in
			pushInstruction('forgetUserOptOut');
		}
		setOpt(!opt);
	}

	return (
		<p className="opt-in-checkbox"><input type="checkbox" id="opt_in_checkbox" onChange={(el) => clickButton(el)} checked={opt} /> <label htmlFor="opt_in_checkbox">{opt ? t['OPT_OUT'] : t['OPT_IN']}</label></p>
	)
}

export default OptOutButton
