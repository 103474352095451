import React from 'react'
import {Link, useParams} from 'react-router-dom'
import Form, { Input } from '../../../components/Form';
import Loading from '../../../components/Loading';

function Integrations({nsp}) {
	const {t, user, site} = nsp;
	const {id} = useParams();

	let comic;
	if (user.comics && Array.isArray(user.comics)) {
		const checkUserArray = user.comics.filter((el) => el.id == id);
		if (checkUserArray.length) {
			comic = checkUserArray[0];
		}
	}

	let vippsSettings;
	if (!comic.orgnr) {
		vippsSettings = <Form 
			nsp={nsp} 
			submitText={t['FETCH_BUSINESS_DETAILS']}
			action={site.api + 'merchants/'}
			formName="newMerchantFromOrgNr"
		>
			<Input nsp={nsp} name="org_nr" label={t['ORG_NUMBER']} description={t['ORG_NUMBER_DESCRIPTION']} mandatory={true} />
		</Form>
	}

	return (
		<div>
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
				{" > "}
				<Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', comic.id)}>{comic.name}</Link>
				{" > "}
			</nav>
			<h2>{t['INTEGRATIONS']}</h2>

			<h3>{t['TAKE_PAYMENTS_WITH_VIPPS']}</h3>
			{vippsSettings}
		</div>
	)
}

export default Integrations
