import { useParams, Link } from 'react-router-dom'
import Comments from '../../../components/Comments'
import Forbidden from '../../Forbidden';

function ComicComments({nsp}) {
	const {t, user} = nsp;
	const {id} = useParams();

	let comic;
	if (user.comics && Array.isArray(user.comics)) {
		const checkUserArray = user.comics.filter((el) => el.id == id);
		if (checkUserArray.length) {
			comic = checkUserArray[0];
		}
	}

	if (!comic) {
		return <Forbidden nsp={nsp} />
	}

	return (
		<div>
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
				{" > "}
				<Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', comic.id)}>{comic.name}</Link>
				{" > "}
			</nav>
			<h2>{t['ALL_COMMENTS']}</h2>
			<Comments nsp={nsp} type="all" comic={comic.id} count="30" />
		</div>
	)
}

export default ComicComments
