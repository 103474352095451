import {useState} from 'react'
import { Helmet } from 'react-helmet';
import { Redirect, Link } from 'react-router-dom';
import Form from '../../../components/Form';
import Forbidden from '../../Forbidden';

function NewComic({nsp}) {
	const {t, user, changeUser, site} = nsp;
	const [comicSaved, setComicSaved] = useState();

	const onSuccess = (data) => {
		if (data.comic) {
			let newUser = {...user};
			let userComics = (user.comics && Array.isArray(user.comics)) ? [...user.comics] : [];
			userComics.push(data.comic);
			newUser.comics = userComics;
			changeUser(newUser);
			setComicSaved(data.comic.id);
		}
	}

	const heading = <>
		<Helmet>
			<title>{t['CREATE_NEW_COMIC']}</title>
		</Helmet>
		<nav className="breadcrumbs">
			<Link to="/">{t['PAGE_TITLE']}</Link>
			{" > "}
			<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
			{" > "}
			<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
			{" > "}
		</nav>
		<h2>{t['CREATE_NEW_COMIC']}</h2>
	</>

	const theForm = <>
		<Form
			submitText = {t['CREATE_COMIC_BUTTON']}
			mode="new"
			action={site.api + 'comics/'}
			onSuccess={onSuccess}
			formName="newComic"
			nsp={nsp}
			fields={[
				{
					label: t['COMIC_NAME'],
					name: "name",
					mandatory: true,
				},
				{
					label: t['COMIC_URL'],
					name: "url",
					mandatory: true,
					description: t['COMIC_URL_DESC'],
					validate: 'regex',
					regex: /^[a-z0-9-]+$/i,
				},
				{
					label: t['COMIC_LANGUAGE'],
					name: "language",
					mandatory: true,
					type: "select",
					default: "nn",
					options: [
						{text: t['LANG_DA'], value: "da"},
						{text: t['LANG_EN'], value: "en"},
						{text: t['LANG_NB'], value: "nb"},
						{text: t['LANG_NN'], value: "nn"},
						{text: t['LANG_SV'], value: "sv"},
					]
				},
				{
					label: t['WHAT_IS_YOUR_ROLE'],
					name: "role",
					mandatory: true,
					type: "select",
					default: "c",
					options: [
						{text: t['CREATOR'], value: "c"},
						{text: t['EDITOR'], value: "e"},
					]
				},
			]}
		/>
	</>;

	if (!user || !user.id) {
		return <>
		{heading}
		<p>{t['CREATION_REQUIRES_LOGIN']}</p>
		</>
	}

	if (user.level < 1) {
		return <>
		{heading}
		<p>{t['CREATION_REQUIRES_CONFIRM']}</p>
		</>
	}

	return (
		<div>
			{comicSaved ? <Redirect push to={`${t['LINK_MY_COMICS']}${comicSaved}/`} /> : (user && user.id ? <>{heading}{theForm}</> : <Forbidden nsp={nsp} />)}
		</div>
	)
}

export default NewComic
