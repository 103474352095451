import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import SvgBalloon from '../media/SvgBalloon';
import { faMoon, faSun, faGlobeEurope } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';

function MenuButtons({nsp, location}) {
	const {darkMode, setDarkMode, changeOverlay} = nsp;
	return (
		<li className="menu-buttons">
			<button onClick={() => {
				if (darkMode) {
					window.localStorage.removeItem("darkMode");
				}
				else {
					window.localStorage.setItem("darkMode", "on");
				}
				setDarkMode(darkMode ? false : true);
			}
			}><Icon icon={darkMode ? faSun : faMoon} /></button>
			{location === 'comic' ? <Link to="/"><SvgBalloon /></Link> : <button onClick={() => changeOverlay('selectLanguage')}><Icon icon={faGlobeEurope} /></button>}
		</li>
	)
}

export default MenuButtons
