import {useState} from 'react';
import Comics from '../../components/Comics';
import {Link} from 'react-router-dom';

function BlockedComics({nsp}) {
	const {t} = nsp;
	const [sortBy, setSortBy] = useState('updated-desc');

	return (
		<div className="comics-page">
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
			</nav>
			<h2>{t['BLOCKED_COMICS']}</h2>
			<p><select value={sortBy} onChange={(el) => setSortBy(el.target.value)}>
				<option value="score">{t['SORT_BY_FOLLOWERS']}</option>
				<option value="created">{t['SORT_BY_CREATION_ASC']}</option>
				<option value="created-desc">{t['SORT_BY_CREATION_DESC']}</option>
				<option value="updated-desc">{t['SORT_BY_LAST_UPDATE']}</option>
				<option value="name">{t['SORT_BY_NAME']}</option>
			</select></p>
			<Comics nsp={nsp} sort={sortBy} count="30" showPagination={true} only="blocked" />
		</div>
	)
}

export default BlockedComics