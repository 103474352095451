import {useState} from 'react'
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import Forbidden from '../../Forbidden';
import Updates from '../../../components/Updates';

function StripSettings({nsp}) {
	const {t, user, site} = nsp;
	const [checkedUpdates, setCheckedUpdates] = useState([]); 
	const [massEditChoice, setMassEditChoice] = useState('album');

	const {id} = useParams();
	let comic;
	if (user.comics && Array.isArray(user.comics)) {
		const checkUserArray = user.comics.filter((el) => el.id == id);
		if (checkUserArray.length) {
			comic = checkUserArray[0];
		}
	}

	if (!comic) {
		return <Forbidden nsp={nsp} />
	}

	const sendMassEdit = async () => {
		if (checkedUpdates.length && massEditChoice === 'album') {
			let body = {
				rows: checkedUpdates.join(),
				mode: 'convertToAlbum',
			};
			const res = await fetch(`${site.api}updates/${id}/`, {
				body: JSON.stringify(body),
				method: 'PATCH',
				credentials: 'include',
				mode: 'cors',
				headers: {'Content-Type':'application/json', 'Accept': 'application/json'},
			});
			const json = await res.json();
		}

		setCheckedUpdates([]);
	}

/*	const massEdit = checkedUpdates.length ? <section className="mass-edit"><p>{checkedUpdates.length === 1 ? t['MASS_EDIT_SELECTED_STRIP'] : t['MASS_EDIT_SELECTED_STRIPS'].replace('{n}', checkedUpdates.length)} <select value={massEditChoice} onChange={el => setMassEditChoice(el.target.value)}>
		<option value="">{t['SELECT_OPTION']}</option>
		<option value="publish">{t['MARK_AS_PUBLISHED']}</option>
		<option value="draft">{t['MARK_AS_DRAFT']}</option>
		<option value="album">{t['CONVERT_TO_ALBUM']}</option>
		<option value="delete">{t['DELETE_SELECTED_STRIPS']}</option>
	</select> <button type="submit" onClick={() => sendMassEdit()} disabled={massEditChoice === ""} className="button">OK!</button></p></section> : ''; */

	return (
		<>
			<Helmet>
				<title>{t['EDIT_STRIPS'].replace('{comic}', comic.name)} :: {t['PAGE_TITLE']}</title>
			</Helmet>
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
				{" > "}
				<Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', comic.id)}>{comic.name}</Link>
				{" > "}
			</nav>
			<h2>{t['EDIT_STRIPS'].replace('{comic}', comic.name)}</h2>

			<p><Link className="button inline" to={`/n/${t['/ME']}/${t['/COMICS']}/${id}/${t['/STRIPS']}/${t['/NEW']}/`}>{t['NEW_STRIP']}</Link><button type="submit" onClick={() => sendMassEdit()} disabled={checkedUpdates.length === 0} className="button">{t['CONVERT_SELECTED_TO_ALBUM']}</button></p>
			<table className="settings-table">
				<thead>
					<tr>
						<th></th>
						<th>{t['TITLE']}</th>
						<th>{t['PUBLICATION_TIME']}</th>
						<th>{t['STATUS']}</th>
						<th className="icon-column">{t['SHOW']}</th>
						<th className="icon-column">{t['DELETE']}</th>
					</tr>
				</thead>
				<Updates nsp={nsp} type="c" display="table" context="edit" comic={id} fields={['checkbox', 'title/image', 'pubtime', 'status', 'showLink', 'delete']} count="20" showPagination={true} checked={checkedUpdates} setChecked={setCheckedUpdates} />
			</table>
		</>
	)
}

export default StripSettings
