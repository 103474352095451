import { useParams } from 'react-router-dom';
import Updates from '../components/Updates';

function Strip({nsp, theComic}) {
	const {slug} = useParams();
	return (
		<div>
			<Updates nsp={nsp} comic={theComic.id} type="c" count="1" slug={slug ? slug : ''} fields={['navigation', 'h3', 'image', 'navigation', 'pubtime', 'text', 'comments']} titleTag={true} />
		</div>
	)
}

export default Strip
