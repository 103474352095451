import {useState} from 'react'
import Loading from './Loading';

function FileUpload({nsp, comic, elementArray, setElementArray, type, albumId, setAlbumId, strip}) {
	const {t, site} = nsp;
	const [currentlyUploading, setCurrentlyUploading] = useState(false);
	const [error, setError] = useState('');
	const [album, setAlbum] = useState(albumId ? albumId : 0);

	const uploadImage = async (el) => {
		if (!el.target.value) {
			return false;
		}
		setCurrentlyUploading(true);
		setError(false);
		const form = document.getElementById('fileUploadForm');
		let formData = new FormData(form);

		const res = await fetch(site.api + 'updates/' + comic + '/', {
			method: 'POST',
			body: formData,
			credentials: 'include',
			mode: 'cors',
		});
		const json = await res.json();
		if (json.error) {
			setError(json.error);
		}
		if (json.album) {
			setAlbum(json.album);
			if (setAlbumId) {
				setAlbumId(json.album);
			}
		}

		if (json.images) {
			let newImageKeys = Object.keys(json.images);
			setElementArray(elementArray.concat(newImageKeys.map((el) => {
				return {id: el, image: json.images[el]};
			})));
		}

		if (strip && json.image) {
			setElementArray({id: strip, image: json.image});
		}
		setCurrentlyUploading(false);
	}

	return (
		<form id="fileUploadForm">
			<p><label className="file-upload-label" htmlFor="file-upload">
				{currentlyUploading ? <Loading tag="span" /> : (strip ? t['CLICK_OR_DRAG_REPLACEMENT_FILE_HERE'] : t['CLICK_OR_DRAG_FILES_HERE'])}
				<input type="file" name="file[]" className="file-upload-input" id="file-upload" accept={`.jpg,.jpeg,.gif,.png${strip ? '' : ',.pdf'}`} onChange={(el) => uploadImage(el)} multiple disabled={currentlyUploading} />
			</label></p>
			<input type="hidden" name="updtype" value={type} />
			{album ? <input type="hidden" name="album" value={album} /> : ''}
			{strip ? <input type="hidden" name="strip" value={strip} /> : ''}
		</form>
	)
}

export default FileUpload