import {useState} from 'react'
import { Link, useParams, Redirect } from 'react-router-dom';
import Form from '../../../components/Form';
import Forbidden from '../../Forbidden';
import {Helmet} from 'react-helmet';

function EditComic({nsp}) {
	const {t, user, changeUser, site} = nsp;
	const [updated, setUpdated] = useState();
	const {id} = useParams();

	let comic;
	if (user.comics && Array.isArray(user.comics)) {
		const checkUserArray = user.comics.filter((el) => el.id == id);
		if (checkUserArray.length) {
			comic = checkUserArray[0];
		}
	}

	const onSuccess = (data) => {
		setUpdated(true);
		setTimeout(() => {
			setUpdated(false);
		}, 3000);

		if (data.changes && (data.changes.name || data.changes.url)) {
			let newUser = {...user};
			let userComics = [];
			if (user.comics && Array.isArray(user.comics)) {
				userComics = user.comics.map((el) => {
					if (el.id == id) {
						el.name = data.changes.name ? data.changes.name : el.name;
						el.url = data.changes.url ? data.changes.url : el.url;
					}
					return el;
				})
			}
			newUser.comics = userComics;
			changeUser(newUser);
		}
	}

	const theForm = <>
		<h2>{t['COMIC_SETTINGS']}</h2>
		<Form
			submitText = {t['UPDATE_COMIC']}
			mode="edit"
			action={`${site.api}comics/${id}/`}
			onSuccess={onSuccess}
			formName="editComic"
			nsp={nsp}
			fields={[
				{
					label: t['COMIC_NAME'],
					name: "name",
					mandatory: true,
				},
				{
					label: t['COMIC_URL'],
					name: "url",
					mandatory: true,
					description: t['COMIC_URL_DESC'],
					validate: 'regex',
					regex: /^[a-z0-9-]+$/i,
				},
				{
					label: t['COMIC_LANGUAGE'],
					name: "language",
					mandatory: true,
					type: "select",
					default: "nn",
					options: [
						{text: t['LANG_DA'], value: "da"},
						{text: t['LANG_EN'], value: "en"},
						{text: t['LANG_NB'], value: "nb"},
						{text: t['LANG_NN'], value: "nn"},
						{text: t['LANG_SV'], value: "sv"},
					]
				},
			]}
		/>
	</>;

	return (
		<div>
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
				{" > "}
				<Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', comic.id)}>{comic.name}</Link>
				{" > "}
			</nav>
			<h2>{t['UPDATE_COMIC']}</h2>
			<Helmet>
				<title>{`${t['UPDATE_COMIC']} : ${t['PAGE_TITLE']}`}</title>	
			</Helmet>
			{updated ? <p className="settings-saved">{t["SETTINGS_SAVED"]}</p> : ""}
			{comic ? theForm : <Forbidden nsp={nsp} />}
		</div>
	)
}

export default EditComic;