import {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet'
import {useParams, Link, Redirect} from 'react-router-dom' 
import Forbidden from '../../Forbidden';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPencilAlt, faSave, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import Form from '../../../components/Form';
import Loading from '../../../components/Loading';

function EditCreators({nsp}) {
	const {t, user, changeUser, site} = nsp;
	const [creators, setCreators] = useState([]);
	const [rowToEdit, setRowToEdit] = useState(0);
	const [userRoles, setUserRoles] = useState(false);
	const [isSendingData, setIsSendingData] = useState(false);
	const [isSendingDeleteRequest, setIsSendingDeleteRequest] = useState(false);
	const [redirectUser, setRedirectUser] = useState(false);
	const {id} = useParams();
	let comic;
	if (user.comics && Array.isArray(user.comics)) {
		const checkUserArray = user.comics.filter((el) => el.id == id);
		if (checkUserArray.length) {
			comic = checkUserArray[0];
		}
	}

	useEffect(() => {
		if (comic) {
			const getCreators = async () => {
				const res = await fetch(`${site.api}comics/${id}/creators/`, {
					credentials: 'include',
				});
				const json = await res.json();
				setCreators(json.data);
				let roleObject = {};
				json.data.forEach(el => roleObject[el.user] = el.role);
				setUserRoles(roleObject);
			}
			getCreators();
		}
	}, [comic]);

	let onAddUser = (data) => {
		let newUser = data.data;
		setCreators([...creators, newUser]);

		let newUserRoles = {...userRoles};
		newUserRoles[newUser.user] = newUser.role;
		setUserRoles(newUserRoles);
	}

	let updateUserRoles = (el) => {
		const {name, value} = el.target;
		let newUserRoles = {...userRoles};
		newUserRoles[name] = value;
		setUserRoles(newUserRoles);
	}

	let editUserRole = async (el) => {
		el.preventDefault();
		setIsSendingData(true);
		let data = {
			user: rowToEdit,
			role: userRoles[rowToEdit],
		}
		const res = await fetch(`${site.api}comics/${id}/creators/`, {
			method: 'PATCH',
			body: JSON.stringify(data),
			mode: 'cors',
			credentials: 'include',
			headers: {'Content-Type':'application/json', 'Accept': 'application/json'},
		})
		const resJson = await res.json();
		if (resJson.error) {
			// Error handling
		}
		else {
			let newCreators = creators.map(el => {
				if (el.user == rowToEdit) {
					let creatorObject = {...el};
					creatorObject.role = userRoles[rowToEdit];
					return creatorObject;
				}
				return el;
			});
			setCreators(newCreators);
			setRowToEdit(0);
		}
		setIsSendingData(false);
	}

	let deleteUser = async (theUser) => {
		setIsSendingDeleteRequest(true);
		let data = {
			user: theUser,
		}
		const res = await fetch(`${site.api}comics/${id}/creators/`, {
			method: 'DELETE',
			body: JSON.stringify(data),
			mode: 'cors',
			credentials: 'include',
			headers: {'Content-Type':'application/json', 'Accept': 'application/json'},
		})
		const resJson = await res.json();
		if (resJson.error) {
			// Error handling
		}
		else {
			let newCreators = creators.filter(el => (el.user !== theUser));
			setCreators(newCreators);
			if (theUser === user.id) {
				setRedirectUser(true);
				const newUserComics = user.comics.filter((el) => el.id != id);
				const newUserObject = {...user, comics: newUserComics};
				changeUser(newUserObject);
			}
		}
		setIsSendingDeleteRequest(false);
	}

	const confirmDelete = (theUser) => {
		let confirm = window.confirm(theUser === user.id ? t['DELETE_SELF_FROM_CREATOR_LIST'] : t['DELETE_USER_FROM_CREATOR_LIST']);
		if (confirm) {
			deleteUser(theUser);
		}
	}

	let showDeleteColumn = (creators.length > 1);
	
	if (redirectUser) {
		return <Redirect push to={t['LINK_MY_COMICS']} />
	}
	if (!comic) {
		return <Forbidden nsp={nsp} />
	}

	if (!creators.length) {
		return <Loading />;
	}

	return (
		<div>
			<Helmet>
				<title>{`${t['CREATORS_AND_EDITORS']} :: ${t['PAGE_TITLE']}`}</title>
			</Helmet>			
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
				{" > "}
				<Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', comic.id)}>{comic.name}</Link>
				{" > "}
			</nav>
			<h2>{t['CREATORS_AND_EDITORS']}</h2>
			<table className="settings-table">
				<thead>
					<tr>
					<th>{t['NAME']}</th>
					<th>{t['ROLE']}</th>
					<th>{t['STATUS']}</th>
					<th className="icon-column">{t['EDIT']}</th>
					{showDeleteColumn ? <th className="icon-column">{t['DELETE']}</th> : ""}</tr>
				</thead>
				<tbody>
				{creators.map((el) => {
					let roleField = (el && el.role) ? t[`CREATOR_ROLE_${el.role.toUpperCase()}`] : "";
					if (rowToEdit === el.user) {

						roleField = isSendingData ? <Loading tag="span" /> : <form onSubmit={editUserRole}><select name={el.user} value={userRoles[el.user]} onChange={updateUserRoles}>
							<option value="c">{t['CREATOR_ROLE_C']}</option>
							<option value="e">{t['CREATOR_ROLE_E']}</option>
						</select>
						<button type="submit" className="link"><Icon icon={faSave} /></button>
						<button className="link"><Icon icon={faUndoAlt} onClick={() => setRowToEdit(0)} /></button>
						</form>
					}
					return <tr key={el.user}>
						<td>{el.name}</td>
						<td>{roleField}</td>
						<td>{el.confirmed ? t["CREATOR_CONFIRMED"] : t["AWAITING_CONFIRMATION"]}</td>
						<td className="edit-row icon-column">
							{rowToEdit === el.user || isSendingData ? "" : <button className="link" onClick={() => setRowToEdit(el.user)}>
								<Icon icon={faPencilAlt} aria-label={t['EDIT']} />
							</button>}
						</td>
						{showDeleteColumn ? <td className="delete-row icon-column">
							{(isSendingDeleteRequest) ? "" : <button className="link" onClick={() => confirmDelete(el.user)}>
								<Icon icon={faTrashAlt} aria-label={t['DELETE']} />
							</button>}
						</td> : ""}</tr>
				})}
				</tbody>
			</table>

			<h3>{t['ADD_CREATOR_OR_EDITOR']}</h3>
			<Form formName="newCreator" submitText={t["SEND_INVITE"]} action={`${site.api}comics/${id}/creators/`} nsp={nsp} onSuccess={onAddUser} fields={[
				{
					name: 'username',
					label: t['USERNAME'],
					mandatory: true,
				},
				{
					name: 'role',
					label: t['ROLE'],
					type: 'select',
					options: [
						{text: t['CREATOR'], value: 'c'},
						{text: t['EDITOR'], value: 'e'},
					],
					default: 'c',
					mandatory: true,
				}
			]} />
			<p>{t['TYPES_OF_ROLES_DESCRIPTION']}</p>
		</div>
	)
}

export default EditCreators;
