function SvgBalloon() {
	return (
		<svg viewBox="0 0 200 170" className="svg-logo">
			<g>
				<path stroke="null" fillRule="nonzero" id="path80" d="m167.32779,113.19276c-0.60933,5.32533 -1.052,11.844 -7.12667,13.47867c-5.556,1.49733 -11.316,2.20667 -17.05067,2.55867c-13.28933,0.80133 -26.59733,-0.036 -39.88666,-0.43467c0.32,0.08133 -22.18267,-0.01733 -28.08267,-0.448c-14.216,-1.04267 -28.752,-2.22267 -42.22933,-7.22133c-6.36267,-2.35867 -12.612,-5.24533 -13.2708,-12.75333c-0.6104,-6.96 -0.81253,-14.01867 -0.7448,-21.00267c0.14067,-14.64933 1.7672,-29.716 4.88693,-44.04266c1.44373,-6.63733 3.97267,-11.98133 9.88467,-15.62933c5.06267,-3.12267 10.46133,-6.704 16.204,-8.368c13.764,-3.992 29.048,-3.35867 43.26,-3.664c15.844,-0.34667 30.7,0.67733 45.756,5.876c6.67067,2.30933 14.608,3.78933 20.636,7.54c4.66667,2.904 7.272,8.21333 9.29067,13.12267c9.36,22.756 1.13333,47.756 -1.52667,70.988zm23.65867,-79.89333c-2.52933,-6.14 -5.78667,-12.776 -11.62,-16.40267c-7.53867,-4.692 -17.452,-6.54267 -25.788,-9.42533c-18.82533,-6.5 -37.39466,-7.776 -57.2,-7.348c-17.76133,0.38133 -36.86933,-0.41067 -54.08,4.58133c-7.17333,2.076 -13.924,6.55467 -20.25027,10.46267c-7.38907,4.55867 -10.54693,11.23333 -12.3552,19.53733c-3.9052,17.90667 -5.92973,36.73867 -6.1084,55.04933c-0.0844,8.73333 0.17093,17.55333 0.93227,26.256c0.82293,9.384 8.63027,12.988 16.5876,15.94133c16.83933,6.248 35.01533,7.72267 52.78733,9.02267c0.24667,0.02 0.468,0.03333 0.70533,0.05333c3.63067,1.04267 6.68,3.06133 10.40667,7.50133c6.064,7.21467 11.49867,15.42267 19.58,20.60933c6.464,4.14267 5.64133,-8.72133 5.79333,-12.088c0.088,-1.86667 1.976,-11.86267 2.324,-15.39333c15.37733,0.52267 30.77067,1.35067 46.148,0.41733c7.172,-0.432 14.36933,-1.32267 21.31867,-3.19467c7.58933,-2.04533 8.14533,-10.188 8.90667,-16.84933c3.32267,-29.02667 13.60667,-60.288 1.912,-88.73066" />
			</g>
		</svg>
	)
}

export default SvgBalloon