import {useState} from 'react';
import Form from '../components/Form';

function UserRegistration({nsp}) {
	const {changeOverlay, changeUser, t, site} = nsp;
	const [registrationComplete, setRegistrationComplete] = useState(false);

	const onSuccess = (data) => {
		changeUser(data.user);
		setRegistrationComplete(true);
	}

	let content;
	if (registrationComplete) {
		content = <>
			<h2>{t['REGISTRATION_COMPLETE']}</h2>
			<p>{t['REGISTRATION_COMPLETE_2']}</p>
			<p>{t['REGISTRATION_COMPLETE_3']}</p>
			<p>{t['REGISTRATION_COMPLETE_4']}</p>
		</>
	}
	else {
		content = <>
		<h2>{t['REGISTER_USER']}</h2>
		<Form 
			nsp={nsp}
			formName="userRegistration"
			action={site.api + 'users/'}
			submitText={t["CREATE_USER"]}
			mode="new"
			onSuccess={onSuccess}
			fields={[
				{
					name: "username",
					label: t['PREFERRED_USERNAME'],
					default: "",
					mandatory: true,
					validate: "notemail",
				},
				{
					name: "email",
					label: t['EMAIL_ADDRESS'],
					default: "",
					mandatory: true,
					validate: "email",
				},
				{
					name: "password", 
					type: "password",
					label: t['PASSWORD'],
					default: "",
					mandatory: true,
				},
				{
					name: "password2", 
					type: "password",
					validate: "compare",
					compare: "password",
					label: t['PASSWORD_REPEAT'],
					default: "",
					mandatory: true,
				},
			]} 
		/>
		<p>{t['ALREADY_REGISTERED']} <button className="link" onClick={() => changeOverlay('log-in')}>{t['LOG_IN']}</button></p>
{/*		<h3>Eller logg inn med ...</h3>
		<ul className="oauth-options">
			<li>Facebook</li>
			<li>Twitter</li>
			<li>Google</li>
		</ul> */}
	</>

	}

	return(content);
}

export default UserRegistration;