import {useState, useEffect} from 'react';
import Loading from './Loading';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import CreatorList from './CreatorList';

function Comics(props) {
	const {nsp, q, showFollowers, sort} = props;
	const jsonAlreadyExists = (props.cache && props.value.length);
	const [comics, setComics] = useState(jsonAlreadyExists ? props.value : []);
	const [loaded, setLoaded] = useState(jsonAlreadyExists ? true : false);
	const [lastLoad, setLastLoad] = useState(0);
	const [pageOffset, setPageOffset] = useState(0);
	
	const getComics = async (nextPage = false) => {
		setLoaded(false);
		if (!nextPage) {
			setPageOffset(0);
			setComics([]);
		}
		let querystring = '';
		const queryElements = ['sort', 'count', 'q', 'page', 'only'];
		queryElements.forEach((key) => {
			if (props[key]) {
				let queryValue = (key === 'page' && nextPage ? (props[key]) + pageOffset + 1 : props[key]);
				querystring += (querystring ? '&' : '?');
				querystring += key + '=' + encodeURIComponent(queryValue);
			}
		})
		if (!props['page'] && nextPage) {
			querystring += (querystring ? '&' : '?');
			querystring += 'page=' + encodeURIComponent(pageOffset + 2);
			setPageOffset(pageOffset + 1);
		}
		
		const res = await fetch(nsp.site.api + 'comics/' + querystring, {credentials: 'include'});
		const comicsFromAPI = await res.json();
		if (nextPage) {
			setComics(comics.concat(comicsFromAPI.data));
		}
		else {
			setComics(comicsFromAPI.data);
		}
		setLoaded(true);
		setLastLoad(comicsFromAPI.data.length);
		if (props.cache) {
			props.setValue(comicsFromAPI.data);
		}
	}
	useEffect(() => {
		if (!jsonAlreadyExists) {
			getComics();
		}

	}, [q, sort]);

	const loadAnotherPage = () => {
		getComics(true);
	}

	let serieElementer = comics.map((comic) => {
		return <article className="single-comic" key={comic.id}>
			<div className="comic-meta">
				<p className="comic-name"><Link to={`/${comic.url}/`}>{comic.name}</Link></p> 
				<CreatorList nsp={nsp} creator={comic.creator} />
			</div>
			{comic.thumb ? <div className="comic-preview">
				<img src={comic.thumb} alt="" />
			</div> : ""}
			{showFollowers ? <p className="followers">{comic.followers} <Icon icon={faStar} /></p> : ''}
		</article>;
	});

	if (loaded && !comics.length && props.ifEmpty) {
		return props.ifEmpty;
	}
	return(
		<>
			<section className="comics-list">
				{(loaded || comics.length) ? serieElementer : [...Array(props.count ? parseInt(props.count) : 10)].map((el, index) => <Loading tag="article" myClassName="single-comic" key={index} />)}
			</section>
			{(props.showPagination && lastLoad == props.count) ? (loaded ? <p><button className="button show-more" onClick={() => loadAnotherPage()}>{nsp.t['SHOW_MORE']}</button></p> : <Loading />) : ''}
		</>
	);
}

export default Comics;