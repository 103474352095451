import {useState} from 'react';
import {useParams} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Form from '../../components/Form';

function ChangePassword({nsp}) {
	const {t, user, site} = nsp;
	const [updated, setUpdated] = useState(false);
	const { token } = useParams();
	const onSuccess = () => {
		setUpdated(true);
	}

	let pageContent;
	if (updated) {
		pageContent = <p>Passordet ditt er oppdatert.</p>
	}
	else if ((user && user.id) || token) {
		let passwordFields = [
			{
				name: "password-new",
				type: "password",
				label: t['PASSWORD_NEW'],
				mandatory: true,
			},
			{
				name: "password-repeat",
				type: "password",
				label: t['PASSWORD_REPEAT'],
				mandatory: true,
				validate: "compare",
				compare: "password-new",
			},
		];
		let action;
		if (token) {
			passwordFields.unshift({
				name: "password-token",
				type: "hidden",
				default: token,
				mandatory: true,
			});
			action = site.api + 'users/'; 
		}
		else {
			passwordFields.unshift({
				name: "password-old",
				type: "password",
				label: t['PASSWORD_OLD'],
				mandatory: true,
			});
			action = `${site.api}users/${user.id}/`;
		}
		pageContent = <Form
		nsp={nsp}
		formName="userSettings"
		action={action}
		submitText={t["CHANGE_PASSWORD"]}
		mode="edit"
		onSuccess={onSuccess}
		fields={passwordFields} 
		doNotFetchInitials={true}
	 />;
	}
	else {
		pageContent = <p>{t['NOT_AUTHORIZED']}</p>;
	}
	
	return (
		<div>
			<Helmet>
				<title>{`${t['CHANGE_PASSWORD']} : ${t['PAGE_TITLE']}`}</title>	
			</Helmet>
			<h2>{t['CHANGE_PASSWORD']}</h2>
			{pageContent}
		</div>
	)
}

export default ChangePassword
