import Form, { Input } from '../components/Form';

function LogInForm({nsp}) {
	const {changeOverlay, changeUser, t, site} = nsp;
	const onSuccess = (data) => {
		changeUser(() => data.user);
		changeOverlay("");
	}

	return(
		<>
			<h2>{t['LOG_IN']}</h2>
			<Form 
				nsp={nsp}
				formName="logInScreen"
				action={site.api + 'log-in/'}
				submitText={t["LOG_IN"]}
				mode="new"
				onSuccess={onSuccess}
			>
				<Input name="username" label={t["USERNAME_OR_EMAIL"]} mandatory={true} />
				<Input type="password" name="password" label={t["PASSWORD"]} mandatory={true} />
				<Input type="checkbox" name="remember" label={t['REMEMBER_ME']} />
			</Form>
			<ul>
				<li><button className="link" onClick={() => changeOverlay('userRegistration')}>{t["REGISTER_USER"]}</button></li>
				<li><button className="link" onClick={() => changeOverlay('forgotPassword')}>{t["FORGOT_PASSWORD"]}</button></li>
			</ul> 
{/*			<h3>{t['ALTERNATIVE_LOGINS']}</h3>
			<ul className="oauth-options">
				<li>Facebook</li>
				<li>Twitter</li>
				<li>Google</li>
			</ul> */}
		</>
	);
}

export default LogInForm;