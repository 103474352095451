import { useState } from 'react';
import Form, { Input, DatePicker } from '../../../components/Form'
import {Link, Redirect, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Forbidden from '../../Forbidden';

function NewBlogPost({nsp}) {
	const {t, user, site} = nsp;
	const [redirect, setRedirect] = useState(false);
	const {id, uid} = useParams();
	let comic;
	if (user.comics && Array.isArray(user.comics)) {
		const checkUserArray = user.comics.filter((el) => el.id == id);
		if (checkUserArray.length) {
			comic = checkUserArray[0];
		}
	}

	const onSuccess = (data) => {
		setRedirect(true);
	}

	if (!comic) {
		return <Forbidden nsp={nsp} />
	}

	if (redirect) {
		return <Redirect push to={`/n/${t['/ME']}/${t['/COMICS']}/${id}/${t['/BLOG']}/`} />
	}

	return (
		<div>
			<Helmet>
				<title>{`${t['EDIT_BLOG_POST']} :: ${t['PAGE_TITLE']}`}</title>
			</Helmet>
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
				{" > "}
				<Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', comic.id)}>{comic.name}</Link>
				{" > "}
				<Link to={t['LINK_ALL_BLOG_POSTS'].replace('{comic}', comic.id)}>{t['ALL_BLOG_POSTS']}</Link>
				{" > "}
			</nav>
			<h2>{t['EDIT_BLOG_POST']}</h2>
			<Form
				formName="newBlogPost"
				action={`${site.api}updates/${id}/${uid}/`}
				submitText={t['UPDATE_POST']}
				onSuccess={onSuccess}
				mode="edit"
				nsp={nsp}
			 >
				 <Input type="hidden" name="updtype" value="b" />
				 <Input type="text" name="title" label={t['TITLE']} mandatory={true} />
				 <Input type="editor" name="text" label={t['BLOG_POST_TEXT']} mandatory={true} />
				 <DatePicker name="pubtime" label={t['PUBLISH_TIME']} />
				 <Input type="select" name="published">
					 <option value="0">{t['DRAFT']}</option>
					 <option value="1">{t['PUBLISHED']}</option>
				 </Input>
			 </Form>
		</div>
	)
}

export default NewBlogPost;