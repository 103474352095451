import {useState} from 'react';
import { Helmet } from "react-helmet";
import { Link, useParams, useHistory } from 'react-router-dom';
import FormattedDate from '../components/FormattedDate';
import Updates from "../components/Updates";

function Daily({nsp}) {
	const {t} = nsp;
	const [inputDate, setInputDate] = useState('');
	const [error, setError] = useState(false);
	const {year, month, date} = useParams();
	const [chosenDate, setChosenDate] = useState(/^[0-9]{4,5}$/.test(year) && /^0[1-9]|1[0-2]$/.test(month) && /^0[1-9]|[12][0-9]|3[0-1]$/.test(date) ? year + '-' + month + '-' + date : false);
	const history = useHistory();

	const changeDate = (e) => {
		e.preventDefault();
		const dateRegex = /^(\d{4,5})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[0-1])$/;
		const dateGroups = inputDate.match(dateRegex);
		if (dateGroups) {
			setError(false);
			setChosenDate(inputDate);
			history.push(`/n/${t['/DAILY']}/${dateGroups[1]}/${dateGroups[2]}/${dateGroups[3]}/`);
		}
		else {
			setError(true);
		}
	}


	const datePickerBox = <section className="grey-box daily-date-box">
		<h3>{t['GO_TO_DATE']}</h3>
		<form onSubmit={(e) => changeDate(e)}>
		<p><input name="datepicker" type="date" value={inputDate} onChange={(e) => setInputDate(e.target.value)} /></p>
		<p><button type="submit">{t['GO_TO_DATE_BUTTON']}</button></p>
		</form>
		{error ? <p className="error-msg">{t['PLEASE_ENTER_VALID_DATE']}</p> : ''}
	</section>

	const title = chosenDate ? FormattedDate({
		nsp: nsp,
		date: chosenDate,
		noTimeTag: true,
	}) : t['DAY_BY_DAY'];	

	return (
		<>
			<Helmet>
				<title>{title + ' :: ' + t['PAGE_TITLE']}</title>
			</Helmet>
			<p className="breadcrumbs">
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				{chosenDate ? <><Link to={`/n/${t['/DAILY']}/`}>{t['DAY_BY_DAY']}</Link>{" > "}</> : ''}
			</p>
			<h2>{title}</h2>
			{datePickerBox}
			<Updates
				nsp={nsp} 
				type="ac"
				fields={[(chosenDate ? 'h3:comicname' : 'h4:comicname'), 'creator', 'linkimage', 'text']} 
				autoAlbum={true}
				dateHeaders={!chosenDate ? "h3": false} 
				count={chosenDate ? -1 : 20}
				sort={chosenDate ? 'score' : false}
				date={chosenDate}
				
				ifEmpty={<p>{t['NO_COMICS_FOUND_THIS_DAY']}</p>}
			/>
		</>
	)
}

export default Daily;