import {useState} from 'react'
import { Helmet } from 'react-helmet';
import { Link, useParams, useHistory } from 'react-router-dom';
import { DragArea, DragObject } from '../../../components/DragAndDrop';
import FileUpload from '../../../components/FileUpload';
import Form, {Input, DatePicker} from '../../../components/Form'
import Forbidden from '../../Forbidden';

function NewAlbum({nsp}) {
	const {t, user, site} = nsp;
	const {id} = useParams();
	const [uploads, setUploads] = useState([]);
	const [albumId, setAlbumId] = useState(0);
	const [values, setValues] = useState(false);
	const history = useHistory();
	let comic;
	if (user.comics && Array.isArray(user.comics)) {
		const checkUserArray = user.comics.filter((el) => el.id == id);
		if (checkUserArray.length) {
			comic = checkUserArray[0];
		}
	}

	if (!comic) {
		return <Forbidden nsp={nsp} />
	}


	const albumSubmitted = (data) => {
		history.push(`/n/${t['/ME']}/${t['/COMICS']}/${id}/${t['/ALBUMS']}/`);
	}

	return (
		<div>
			<Helmet>
				<title>{`${t['NEW_ALBUM']} :: ${t['PAGE_TITLE']}`}</title>
			</Helmet>
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
				{" > "}
				<Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', comic.id)}>{comic.name}</Link>
				{" > "}
				<Link to={`/n/${t['/ME']}/${t['/COMICS']}/${id}/${t['/ALBUMS']}/`}>{t['ALL_ALBUMS']}</Link>
				{" > "}
			</nav>
			<h2>{t['NEW_ALBUM']}</h2>

			<FileUpload nsp={nsp} comic={id} uploads={uploads} setUploads={setUploads} elementArray={uploads} setElementArray={setUploads} type="ap" setAlbumId={setAlbumId} />

			{uploads.length ?
				<>
					<p>{t['UPLOAD_SAVED_IN_DRAFTS_ALBUM']}</p>
					<Form
						nsp={nsp}
						submitText={t['PUBLISH_ALBUM']}
						draftText={t['SAVE_AS_DRAFT']}
						formName="editAlbum"
						setFormValues={setValues}
						onSuccess={albumSubmitted}
						mode="edit"
						doNotFetchInitials={true}
						action={`${site.api}updates/${id}/${albumId}/`}
						>
						<Input type="hidden" name="pages" value={uploads.map(el => el.id).join()} />
						<Input name="title" type="text" label={t['TITLE']} mandatory={true} />
						<Input name="text" type="editor" label={t['DESCRIPTION']}/>
						<DatePicker name="pubtime" label={t['PUBLISH_TIME']} />
						</Form>
						<section className="album-pages">
							<DragArea uploads={uploads} setUploads={setUploads}>
							{uploads.map((el, index) => {
								return <DragObject
									type="article"
									myClassName="page"
									key={index} 
									elementId={el.id}
								>
									<p>{t['PAGE_X'].replace('{n}', (index + 1))}</p>
									<p className="image"><img src={`${site.media}thumbs/${el.image}`} alt={t['THUMB_UPLOAD_ALT_TEXT'].replace('{filename}', values['title-' + el.id])} /></p>
									<p>{el.image}</p>
								</DragObject>

							})}
						</DragArea>
					</section>
			</> : ""}
		</div>
	)
}

export default NewAlbum;