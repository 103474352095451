import { Switch, Route } from 'react-router-dom';
import Blog from '../pages/Blog'
import Searchbar from '../components/Searchbar';
import Frontpage from '../pages/Frontpage';
import UserMain from '../pages/user/UserMain';
import Daily from '../pages/Daily';
import Search from '../pages/Search';
import About from '../pages/About';
import ComicList from '../pages/ComicList';
import AllComments from '../pages/AllComments';

function Main({nsp}) {
	return (
		<main className="main">
			<Switch>
				<Route path={["/n/me", "/n/meg"]}>
					<UserMain nsp={nsp} />
				</Route>
				<Route path={["/n/blog", "/n/blogg"]}>
					<Blog nsp={nsp} />
				</Route>
				<Route path={["/n/comics", "/n/seriar"]}>
					<ComicList nsp={nsp} />
				</Route>
				<Route path={["/n/comments", "/n/kommentarar"]}>
					<AllComments nsp={nsp} />
				</Route>
				<Route path={["/n/daily/:year/:month/:date", "/n/dagfordag/:year/:month/:date", "/n/daily", "/n/dagfordag"]}>
					<Daily nsp={nsp} />
				</Route>
				<Route path={["/n/search", "/n/sok"]}>
					<Search nsp={nsp} />
				</Route>
				<Route path={["/n/help/:page", "/n/hjelp/:page"]}>
					<About nsp={nsp} />
				</Route>
				<Route path={["/n/help", "/n/hjelp"]}>
					<About nsp={nsp} />
				</Route>
				<Route path="/">
					<Searchbar nsp={nsp} />
					<Frontpage nsp={nsp} />
				</Route>
			</Switch>
		</main>
	);
}

export default Main;