import {useState, useEffect, Fragment} from 'react'
import {Link} from 'react-router-dom'
import FormattedDate from './FormattedDate';
import Loading from './Loading';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faEye, faFastBackward, faFastForward, faShareAlt, faStepBackward, faStepForward, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet';
import CreatorList from './CreatorList';
import Album from './Album';
import Comments from './Comments';

function Updates(props) {
	const {nsp, comic, slug, updateId, display, context, fields, titleTag, dateHeaders, type, count, page, date, ifEmpty, refresh, className, autoAlbum, checked, setChecked} = props;
	const {t, site} = nsp;
	const [content, setContent] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [lastLoad, setLastLoad] = useState(0);
	const [pageOffset, setPageOffset] = useState(0);
	const [reFetching, setRefetching] = useState(false);
	const [isSendingDeleteRequest, setIsSendingDeleteRequest] = useState(false);
	const [checkedBoxes, setCheckedBoxes] = useState(checked ? checked : []);

	const getUpdates = async (nextPage = false) => {
		setLoaded(false);
		if (!nextPage) {
			setRefetching(true);
			setPageOffset(0);
		}
		let querystring = '';
		const queryElements = ['type', 'count', 'page', 'context', 'date', 'sort'];
		queryElements.forEach((key) => {
			if (props[key]) {
				let queryValue = (key === 'page' && nextPage ? (props[key]) + pageOffset + 1 : props[key]);
				querystring += (querystring ? '&' : '?');
				querystring += key + '=' + encodeURIComponent(queryValue);
			}
		});
		if (!props['page'] && nextPage) {
			querystring += (querystring ? '&' : '?');
			querystring += 'page=' + encodeURIComponent(pageOffset + 2);
			setPageOffset(pageOffset + 1);
		}

		if (fields.includes('navigation')) {
			querystring += (querystring ? '&' : '?') + 'navigation=1';
		}
		if (fields.includes('album')) {
			querystring += (querystring ? '&' : '?') + 'albumView=1';
		}
		if (fields.includes('thumb')) {
			querystring += (querystring ? '&' : '?') + 'thumbs=1';
		}

		const res = await fetch(site.api + 'updates/' + (comic ? comic + "/" + (slug ? "_" + slug + "/" : (updateId ? updateId + "/" : "")) : "") + querystring, {
			method: "GET",
			credentials: "include",
		});
		const json = await res.json();
		if ((slug || updateId) && json.data) {
			setContent([json.data]);
		}
		else if (nextPage && json.data && json.data.length) {
			setContent(content.concat(json.data));
		}
		else if (json.data && json.data.length) {
			setContent(json.data);
		}
		if (json['request_time']) {
			setLoaded(json['request_time']);
		}
		else {
			setLoaded(true);
		}
		setLastLoad(json.data && json.data.length ? json.data.length : 0);


		setRefetching(false);
	}

	useEffect(() => {
		getUpdates();
	}, [type, count, page, date, context, slug, refresh]);

	if (!loaded && !content.length) {
		return display === 'table' ? <Loading tag="tbody" /> : <Loading />;
	}
	
	let WrapperTag = display === "table" ? "tbody" : "section";
	let ArticleTag = display === "table" ? "tr" : "article";
	let FieldTag = display === "table" ? "td" : "p";

	const headerTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'title'];
	const otherTags = ['div'];
	const linktype = {a: t['/ALBUMS'], b: t['/BLOG'], c: t['/STRIPS']};

	if (!content) {
		if (ifEmpty) {
			return ifEmpty;
		}
		return false;
	}

	const deleteDialog = {a: t['DELETE_ALBUM_CONFIRM'], b: t['DELETE_BLOG_CONFIRM'], c: t['DELETE_STRIP_CONFIRM']};

	const deleteUpdate = async (upd) => {
		setIsSendingDeleteRequest(true);
		const res = await fetch(`${site.api}updates/${comic}/${upd}/`, {
			method: 'DELETE',
			mode: 'cors',
			credentials: 'include',
			headers: {'Content-Type':'application/json', 'Accept': 'application/json'},
		})
		const resJson = await res.json();
		if (resJson.error) {
			// Error handling
		}
		else {
			setContent(content.filter(el => el.id !== upd));
		}
		setIsSendingDeleteRequest(false);
	}

	const confirmDelete = (theUpdate, type) => {
		let confirm = window.confirm(deleteDialog[type]);
		if (confirm) {
			deleteUpdate(theUpdate);
		}
	}

	const loadAnotherPage = () => {
		getUpdates(true);
	}

	const checkBox = (el) => {
		let newChecked;
		if (checked.includes(el.target.name)) {
			newChecked = checkedBoxes.filter(post => post != el.target.name);
		}
		else {
			newChecked = [...checked, el.target.name];
		}
		setChecked(newChecked);
		setCheckedBoxes(newChecked);
	}

	let DateTag = 'h3';
	if (dateHeaders && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(dateHeaders)) {
		DateTag = dateHeaders;
	}

	const nextButton = <button className="button show-more" onClick={() => loadAnotherPage()}>{nsp.t['SHOW_MORE']}</button>;

	let dayHeader;
	return (
		<>
		<WrapperTag className={`${reFetching ? "refetching": ''}${className ? ' ' + className : ''}`}>
			{titleTag && content && content.length ? <Helmet>
				<title>{(content[0].title ? content[0].title : FormattedDate({nsp: nsp, date: content[0].pubtime, noTimeTag: true, dateOnly: true}))} :: {(content[0].comicname)}</title>
			</Helmet> : undefined}{content.map((el) => {
				const fieldsArray = (autoAlbum && el.updtype == 'a') ? ['album'] : fields;
				if (fieldsArray) {
					let dateHeader;
					if (dateHeaders && dayHeader !== el.pubtime.substring(0, 10)) {
						dateHeader = <DateTag><FormattedDate nsp={nsp} date={el.pubtime} dateOnly={true} /></DateTag>;
						dayHeader = el.pubtime.substring(0, 10);
					}
					return <Fragment key={el.id}>
					<ArticleTag className={`${el.comicurl === 'info' ? 'featured' : ''}`}>
						{dateHeader}
						{fieldsArray.map((field, index) => {
						let fieldType;
						if (field.includes(':')) {
							const [type, content] = field.split(':');
							fieldType = type;
							field = content;
						}
						else {
							fieldType = field;
						}

						let TagType;
						if ((headerTags.includes(fieldType) && fieldType !== 'title') || otherTags.includes(fieldType)) {
							TagType = fieldType;
						}
						else {
							TagType = FieldTag;
						}

						if (field === 'text') {
							return <div className="user-html" key={index} dangerouslySetInnerHTML={{__html: el.text}} />;
						}

						let c;
						let classes;
						let editLink = `/n/${t['/ME']}/${t['/COMICS']}/${el.comicid}/${linktype[el.updtype]}/${el.id}/`;
						let viewLink = `/${el.comicurl}/${linktype[el.updtype]}/${el.slug}/`;

						if (field === 'album') {
							return <Album key={index} nsp={nsp} albumInfo={el}heading={slug ? <h2>{el.title}</h2> : (autoAlbum ? <h4><Link to={viewLink}>{el.title}</Link></h4> : <h3><Link to={viewLink}>{el.title}</Link></h3>)} showComicName={autoAlbum} />
						}

						if (field === 'comments') {
							return <Comments key={index} nsp={nsp} parent={el.id} showForm={true} />
						}
						
						if (headerTags.includes(field)) {
							c = (slug) ? ((el.title || display !== 'table') ? el.title : t['NO_TITLE']) : <Link to={context === 'edit' ? editLink : viewLink}>{(el.title || display !== 'table') ? el.title : t['NO_TITLE']}</Link>;
						}

						else if (field === 'title/image') {
							c = (slug) ? el.title : <Link to={context === 'edit' ? editLink : viewLink}>{el.title}</Link>;
							c = el.title ? <Link to={context === 'edit' ? editLink : viewLink}>{el.title}</Link> : <Link to={context === 'edit' ? editLink : viewLink}>{el.image}</Link>;
						}

						else if (field === 'abstract') {
							c = el.abstract;
						}

						else if (field === 'image') {
							c = <img src={site.media + 'updates/' + el.image} alt={el.title ? el.title : `${el.comicname} - ${el.pubtime}`} key={el.image} />;
						}

						else if (field === 'linkimage') {
							c = <Link to={viewLink}><img src={site.media + 'updates/' + el.image} alt={el.title ? el.title : `${el.comicname} - ${el.pubtime}`} key={el.image} /></Link>;
						}

						else if (field === 'thumb') {
							c = <Link to={viewLink}><img src={site.media + 'thumbs/' + el.image} alt={el.title ? el.title : `${el.comicname} - ${el.pubtime}`} key={el.image} /></Link>;
						}

						else if (field === 'comicname') {
							c = <Link to={`/${el.comicurl}/`}>{el.comicname}</Link>;
						}

						else if (field === 'creator') {
							c = <CreatorList nsp={nsp} creator={el.creator} />
							TagType = "div";
						}

						else if (field === 'pubtime') {
							c = <FormattedDate nsp={nsp} date={el.pubtime} />;
						}

						else if (field === 'status') {
							c = el.published ? (loaded >= el.pubtime ? t['PUBLISHED'] : t['SCHEDULED']) : t['DRAFT'];
						}

						else if (field === 'showLink' && el.slug && el.published && loaded >= el.pubtime) {
							c = <Link to={viewLink}><Icon icon={faEye} /></Link>
							if (display === 'table') {
								classes='icon-column';
							}
						}

						else if (field === 'navigation') {
							TagType = 'nav';
							c = <>
							<p>
								{el.first ? <Link to={`/${el.comicurl}/${linktype[el.updtype]}/${el.first.slug}/`}><Icon icon={faFastBackward} /></Link> : null}
							</p>
							<p>
								{el.previous ? <Link to={`/${el.comicurl}/${linktype[el.updtype]}/${el.previous.slug}/`}><Icon icon={faStepBackward} /></Link> : null}
							</p>
							{/* <p><Icon icon={faShareAlt} /></p> */}
							<p>
								{el.next ? <Link to={`/${el.comicurl}/${linktype[el.updtype]}/${el.next.slug}/`}><Icon icon={faStepForward} /></Link> : null}
							</p>
							<p>
								{el.last ? <Link to={`/${el.comicurl}/${linktype[el.updtype]}/${el.last.slug}/`}><Icon icon={faFastForward} /></Link> : null}
							</p>
							<Helmet>
								{el.first ? <link rel="prefetch" href={site.media + 'updates/' + el.first.image} as="image" /> : null}
								{el.previous ? <link rel="prefetch" href={site.media + 'updates/' + el.previous.image} as="image" /> : null}
								{el.next ? <link rel="prefetch" href={site.media + 'updates/' + el.next.image} as="image" /> : null}
								{el.last ? <link rel="prefetch" href={site.media + 'updates/' + el.last.image} as="image" /> : null}
							</Helmet>
							</>
						}

						else if (field === 'readMore') {
							c = <Link to={viewLink}>{t['READ_MORE']}</Link>
						}

						else if (field === 'delete') {
							c = <button onClick={() => confirmDelete(el.id, el.updtype)} className="link"><Icon icon={faTrashAlt} /></button>
							if (display === 'table') {
								classes='icon-column delete-row';
							}
						}

						else if (field == 'checkbox') {
							c = <input type="checkbox" name={el.id} checked={checked.includes(el.id.toString()) ? 'checked' : false} onChange={(e) => checkBox(e)} id={`checkbox-${el.id}`} />
						}

						if (c || display === 'table') {
							return <TagType className={`update-${field === 'title/image' ? 'title-image' : field} ${classes ? classes : ""}`} key={index}>{c}</TagType>;
						}
					})}</ArticleTag>
					</Fragment>
				}
			})}
			{(props.showPagination && lastLoad == props.count && display === 'table') ? (loaded ? <tr><td colSpan={fields.length}>{nextButton}</td></tr> : <Loading />) : null}
		</WrapperTag>{(props.showPagination && lastLoad == props.count && display !== 'table') ? (loaded ? <p>{nextButton}</p> : <Loading />) : null}</>
	)
}

export default Updates;