import {useState} from 'react';
import Form from '../../components/Form';

function ForgotPassword({nsp}) {
	const {changeOverlay, t, site} = nsp;
	let [sent, setSent] = useState(false);
	const onSuccess = (data) => {
		setSent(true);
	}

	if (sent) {
		return(
			<>
				<h2>{t['FORGOT_PASSWORD']}</h2>
				<p>{t['PASSWORD_CODE_SENT']}</p>
			</>
		);
	}
	else {
		return(
			<>
				<h2>{t['FORGOT_PASSWORD']}</h2>
				<p>{t['SENDING_PASSWORD_CODE']}</p>
				<Form 
					nsp={nsp}
					formName="logInScreen"
					action={site.api + 'forgot-password/'}
					submitText={t["LOG_IN"]}
					mode="new"
					onSuccess={onSuccess}
					fields={[
						{
							name: "email",
							label: t["EMAIL_ADDRESS"],
							default: "",
							mandatory: true,
						},
					]} 
				/>
				<ul>
					<li><button className="link" onClick={() => changeOverlay('userRegistration')}>{t["REGISTER_USER"]}</button></li>
					<li><button className="link" onClick={() => changeOverlay('log-in')}>{t["LOG_IN"]}</button></li>
				</ul> 
{/*				<h3>{t['ALTERNATIVE_LOGINS']}</h3>
				<ul className="oauth-options">
					<li>Facebook</li>
					<li>Twitter</li>
					<li>Google</li>
				</ul> */}
			</>
		);
	}

}

export default ForgotPassword;