import React from 'react'
import { Helmet } from 'react-helmet';
import Updates from '../components/Updates';
import {Link} from 'react-router-dom';

function Blog({nsp, theComic}) {
	const {t} = nsp;
	return (
		<div className="comic-blog-page">
			<Helmet>
				<title>{t['BLOG']} :: {theComic.name}</title>
			</Helmet>
			<p className="breadcrumbs">
				<Link to={`/${theComic.url}/`}>{theComic.name}</Link>
				{" > "}
			</p>
			<h2>{t['BLOG']}</h2>
			<Updates nsp={nsp} type="b" comic={theComic.id} count="10" fields={['h3', 'pubtime', 'text']} showPagination={true} />
		</div>
	)
}

export default Blog
