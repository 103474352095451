import {useState, Children, isValidElement, cloneElement} from 'react'

function DragArea({uploads, setUploads, children}) {
	const dragElementType = (<DragObject />).type.name;
	const [dragObject, setDragObject] = useState(false);

	const elementSwap = (dragged, target) => {
		// Get the row we're currently dragging 
		const currentElement = uploads.filter((el) => 'element-' + el.id === dragged);

		// New array ...
		let swappedUploads = [];
		let afterCurrent = false;
		uploads.forEach((el) => {

			if ('element-' + el.id === target) {
				if (!afterCurrent) {
					swappedUploads.push(currentElement[0]);
					swappedUploads.push(el);
				}
				else {
					swappedUploads.push(el);
					swappedUploads.push(currentElement[0]);
				}
			}
			else if ('element-' + el.id === dragged) {
				afterCurrent = true;
			}
			else {
				swappedUploads.push(el);
			}
		});
		setUploads(swappedUploads);
	}

	const dragElement = (e) => {
		if (e.currentTarget.dataset.dragbox) {
			e.dataTransfer.setData('text', e.currentTarget.id);
			setDragObject(e.currentTarget.id);
			let thumb = new Image();
			thumb.src = document.querySelector('#' + e.currentTarget.id + ' .image img').getAttribute('src');
			e.dataTransfer.setDragImage(thumb, thumb.width / 2, thumb.height / 2);
		}
		else {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	const dragOverElement = (e) => {
		e.preventDefault();
		if (e.currentTarget.dataset.dragbox && dragObject !== e.currentTarget.id) {
			elementSwap(dragObject, e.currentTarget.id);
		}
	}

	const dropElement = (e) => {
		e.preventDefault();
		setDragObject('');
	}

	const modifiedChildren = (c) => Children.map(c, child => {
		if (isValidElement(child)) {
			if (child.type.name === dragElementType) {
				return cloneElement(child, {
					...child.props,
					dragElement: dragElement,
					setDragObject: setDragObject,
					dragOverElement: dragOverElement,
					dropElement: dropElement,
					dragObject: dragObject,
				 })
			}
		}
		return child;
	});


	return (
		<>
			{modifiedChildren(children)}
		</>
	)
}

function DragObject({type, myClassName, children, dragObject, elementId, dragElement, setDragObject, dragOverElement, dropElement}) {
	let TagType = 'div';
	if (type) {
		TagType = type;
	}
	return (
		<TagType
			className={`${myClassName ? myClassName : ''} ${(dragObject === 'element-' + elementId) ? 'being-dragged' : ''}`} 
			id={'element-' + elementId} 
			draggable={true} 
			data-dragbox={true}
			onDragStart={(e) => dragElement(e)}
			onDragEnd={() => setDragObject(false)}
			onDragEnter={(e) => dragOverElement(e)}
			onDrop={(e) => dropElement(e)}


		>{children}</TagType>
	);
}

export {DragArea, DragObject}
