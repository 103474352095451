import {useState} from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faStepBackward, faStepForward, faSearchPlus, faTimes, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import vippsNo from '../media/pay_with_vipps_rect_210_NO.svg';
import Loading from './Loading';
import { Link } from 'react-router-dom';

function Album({albumInfo, nsp, heading, showComicName}) {

	const {t, site} = nsp;

	const [showAlbum, setShowAlbum] = useState(false);

	const [current, setCurrent] = useState(0);
	const [direction, setDirection] = useState('no-flip');
	const [zoom, setZoom] = useState(false);
	const [menuClass, setMenuClass] = useState('initial');
	const [showInstructions, setShowInstructions] = useState('hide-instructions');
	const [purchasing, setPurchasing] = useState(false);

	const numberOfPages = albumInfo.pages.length;

	const prevPage = () => {
		if (current > 0) {
			setCurrent(current - 1);
			setDirection('flip-backward');
		}
		setMenuClass(menuClass === 'initial' ? 'initial' : 'hide');
		setShowInstructions('hide-instructions');
	}

	const nextPage = () => {
		if (current < numberOfPages - 1) {
			setCurrent(current + 1);
			setDirection('flip-forward');
		}
		setMenuClass(menuClass === 'initial' ? 'initial' : 'hide');
		setShowInstructions('hide-instructions');
	}

	const slideToPage = (e) => {
		setDirection('no-flip');
		setCurrent(parseInt(e.target.value));		
	}

	const zoomIn = () => {
		setZoom(true);
		setMenuClass(menuClass === 'initial' ? 'initial' : 'hide');
		setShowInstructions('hide-instructions');
	}

	const openAlbum = () => {
		setShowAlbum(true);
	}

	const closeAlbum = () => {
		setDirection('no-flip');
		setShowAlbum(false);

	}

	const albumOverlay = <div className={`album-view ${direction}`}>
		<Helmet>
			<body data-view="album"></body>
		</Helmet>
		{current > 0 ? <div className="previous">
		<img src={`${site.media}updates/${albumInfo.pages[current - 1]}`} alt="" key={current - 1} /></div> : ''}
		<div className="current">
		<img src={`${site.media}updates/${albumInfo.pages[current]}`} alt="" key={current} /></div>
		{current < numberOfPages - 1 ? <div className="next">
				<img src={`${site.media}updates/${albumInfo.pages[current + 1]}`} alt="" key={current + 1} />
			</div> : ''
		}
		<button className="album-show-menu" onClick={() => setMenuClass('show')}></button>
		<div className={`album-menu-buttons album-menu-${menuClass}`}>
			<p className="album-title" onClick={() => setMenuClass('hide')}>{albumInfo.title}</p>
			<p className="buttons"><button className="link" onClick={() => setShowInstructions(showInstructions === 'show-instructions' ? 'hide-instructions' : 'show-instructions')}><Icon icon={faQuestionCircle} /></button></p>
			<p className="buttons"><button className="link" onClick={closeAlbum}><Icon icon={faTimes} /></button></p>
		</div>
		<div className={`album-flip-buttons ${showInstructions}`}>
			<button className="button-prev" onClick={() => prevPage()}><div className="instruction"><p><Icon icon={faStepBackward} /></p><p>{t['PREVIOUS_PAGE']}</p></div></button>
			<button className="button-zoom" onClick={() => zoomIn()}><div className="instruction">
				<p><Icon icon={faSearchPlus} /></p>
				<p>{t['ZOOM_IN']}</p>
			</div></button>
			<button className="button-next" onClick={() => nextPage()}><div className="instruction">
				<p><Icon icon={faStepForward} /></p>
				<p>{t['NEXT_PAGE']}</p>
			</div></button>
		</div>
		<button className="album-show-menu-2" onClick={() => setMenuClass('show')}></button>
		<div className={`album-slider album-slider-${menuClass}`}>
			<p className="slider-container"><input type="range" min="0" max={(numberOfPages - 1)} value={current} onInput={(e) => slideToPage(e)} /></p>
			<p className="page-number">{(current + 1)} / {numberOfPages}</p>
		</div>
		<div className={zoom ? 'zoomed-in-page' : 'invisible'} onClick={() => setZoom(false)}>
			<img src={`${site.media}updates/${albumInfo.pages[current]}`} alt="" />
		</div>
	</div>;

	let headerTag = <h3>{albumInfo.title}</h3>;

	const purchaseAlbum = async () => {
		setPurchasing(true);

		let formData = new FormData();
		formData.append('payment_type', 'vipps');

		const res = await fetch(site.api + 'updates/' + albumInfo.comicid + '/' + albumInfo.id + '/purchase/', {
			method: 'POST',
			body: formData,
			credentials: 'include',
			mode: 'cors',
		});
		const json = await res.json();

		if (json.url) {
			window.location.href = json.url;
		}
		else {
			setPurchasing(false);
		}

		
	};

	let priceString;
	if (albumInfo.price % 100 === 0) {
		priceString = (albumInfo.price / 100) + ' kr';
	}
	else {
		priceString = (albumInfo.price / 100).toFixed(2) + ' kr';
	}

	const purchase = <>
		<p className="album-price">{priceString}</p>
		<p className="read-buttons">
			<button className="button" onClick={openAlbum}>{t['READ_PREVIEW']}</button>
			{purchasing ? <Loading tag="span" /> : <button className="link" onClick={purchaseAlbum}><img src={vippsNo} alt="" /></button>}
		</p>
	</>;

	return (
		<div className="album-presentation">
			<p className="album-cover">
				<img src={`${site.media}updates/${albumInfo.pages[0]}`} alt="" onClick={openAlbum} />
			</p>
			<div className="album-text">
				{showComicName ? <h3><Link to={`/${albumInfo.comicurl}/`}>{albumInfo.comicname}</Link>:</h3> : null}
				{heading ? heading : <h3>{albumInfo.title}</h3>}
				{albumInfo.text ? <div className="user-html" dangerouslySetInnerHTML={{__html: albumInfo.text}} /> : ''}
				{albumInfo.price ? purchase : <p className="read-buttons"><button className="button" onClick={openAlbum}>{t['READ']}</button></p>}
			</div>
			{showAlbum ? albumOverlay : ''}
		</div>
	)
}

export default Album
