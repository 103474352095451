import {useState, useEffect} from 'react'
import { Link, useParams, useHistory } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import Forbidden from '../../Forbidden';
import {Helmet} from 'react-helmet';
import Form, {Input} from '../../../components/Form';
import FileUpload from '../../../components/FileUpload';

function Appearance({nsp}) {
	const {t, user, site} = nsp;
	const [updated, setUpdated] = useState(false);
	const [values, setValues] = useState(false);
	const [uploads, setUploads] = useState([]);
	const [isSendingDeleteRequest, setIsSendingDeleteRequest] = useState(false);
	const {id} = useParams();
	const history = useHistory();

	useEffect(() => {
		const getComic = async () => {
			let res = await fetch(`${site.api}comics/${id}/?view=layout`, {credentials: 'include'});
			let json = await res.json();
			let loadedComic = json.data;
			if (loadedComic.banner) {
				setUploads([loadedComic.banner]);
			}
		} 
		getComic();
		
	}, []);

	let stylesString = '';

	stylesString = typeof values === 'object' ? Object.keys(values).map(el => {
		return '--' + el + ': ' + values[el] + ';';
	}).join("\n") : '';

	const GlobalStyles = createGlobalStyle`
	body {
		${stylesString}
	}
	`;

	let comic;
	if (user.comics && Array.isArray(user.comics)) {
		const checkUserArray = user.comics.filter((el) => el.id == id);
		if (checkUserArray.length) {
			comic = checkUserArray[0];
		}
	}

	if (!comic) {
		return <Forbidden nsp={nsp} />
	}

	const onSuccess = (data) => {
		history.push(`/n/${t['/ME']}/${t['/COMICS']}/${id}/`);
	}

	const deleteBanner = async () => {
		setIsSendingDeleteRequest(true);
		const res = await fetch(`${site.api}updates/${id}/banner/`, {
			method: 'DELETE',
			mode: 'cors',
			credentials: 'include',
			headers: {'Content-Type':'application/json', 'Accept': 'application/json'},
		})
		const resJson = await res.json();
		if (resJson.error) {
			// Error handling
		}
		else {
			setUploads([]);
		}
		setIsSendingDeleteRequest(false);
	}

	return (
		<div>
			<GlobalStyles />
			<Helmet>
				<title>{`${t['APPEARANCE_SETTINGS']} : ${t['PAGE_TITLE']}`}</title>	
			</Helmet>
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_COMICS']}>{t['MY_COMICS']}</Link>
				{" > "}
				<Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', comic.id)}>{comic.name}</Link>
				{" > "}
			</nav>
			<h2>{t['APPEARANCE_SETTINGS']}</h2>
			<p>{t['APPEARANCE_DESCRIPTION']}</p>
			<Form nsp={nsp} submitText={t['UPDATE_APPEARANCE']} formName="appearance" mode="edit" action={`${site.api}comics/${id}/`} onSuccess={onSuccess} setFormValues={setValues}>

				<h3>{t['MAIN_FIELD']}</h3>
				<Input type="color" name="main-text-color" label={t['COLOR_TEXT']} />
				<Input type="color" name="main-background-color" label={t['COLOR_BACKGROUND']} />
				<Input type="color" name="main-title-color" label={t['COLOR_TITLES']} />
				<Input type="color" name="main-link-color" label={t['COLOR_LINKS']} />
				<Input type="color" name="main-accent-color" label={t['COLOR_ACCENT']} />
				<Input type="color" name="on-accent-color" label={t['COLOR_ON_ACCENT']} />

				<h3>{t['MENU']}</h3>
				<Input type="color" name="menu-link-color" label={t['COLOR_LINKS']} />
				<Input type="color" name="menu-background-color" label={t['COLOR_BACKGROUND']} />
				<Input type="color" name="menu-border-color" label={t['COLOR_BORDER']} />

				<h3>{t['FOOTER']}</h3>
				<Input type="color" name="box-background-color" label={t['COLOR_BACKGROUND']} />
				<Input type="color" name="box-border-color" label={t['COLOR_BORDER']} />

				<h3>{t['BACKGROUND']}</h3>
				<Input type="color" name="page-background-color" label={t['COLOR_BACKGROUND']} />
				<Input type="color" name="wrapper-border-color" label={t['COLOR_WRAPPER_BORDER']} />

				<h3>{t['FORM_FIELDS']}</h3>
				<Input type="color" name="form-text-color" label={t['COLOR_TEXT']} />
				<Input type="color" name="form-background-color" label={t['COLOR_BACKGROUND']} />
				<Input type="color" name="form-accent-color" label={t['COLOR_ALTERED']} />

				<h3>{t['DARK_MODE']}</h3>
				<p>{t['DARK_MODE_EXPLANATION']}</p>
				<Input type="color" name="darkmode-title-color" label={t['COLOR_TITLES']} />
				<Input type="color" name="darkmode-link-color" label={t['COLOR_LINKS']} />
				<Input type="color" name="darkmode-accent-color" label={t['COLOR_ACCENT']} />
				<Input type="color" name="darkmode-menu-link-color" label={t['COLOR_MENU_LINKS']} />
			</Form>

			<h3>{t['UPLOAD_BANNER']}</h3>
			{uploads.length === 0 ? <>
				<p>{t['UPLOAD_BANNER_DESCRIPTION']}</p>
				<FileUpload nsp={nsp} comic={id} elementArray={uploads} setElementArray={setUploads} type="h" />
			</> : <>
				<p>{t['UPLOAD_BANNER_DONE']}</p>
				<FileUpload nsp={nsp} comic={id} elementArray={uploads} setElementArray={setUploads} type="h" />
				<p><button className="link" onClick={() => deleteBanner()}>{t['UPLOAD_BANNER_DELETE']}</button></p>
			</>}
		</div>
	)
}
export default Appearance
