import {Link, useParams} from 'react-router-dom';
import Updates from '../components/Updates';

function BlogPost({nsp, theComic}) {
	const {t} = nsp;
	const {slug} = useParams();

	return (
		<>
			<p className="breadcrumbs">
				<Link to={`/${theComic.url}/`}>{theComic.name}</Link>
				{" > "}
				<Link to={`/${theComic.url}/${t['/BLOG']}/`}>{t['BLOG']}</Link>
				{" > "}
			</p>
			<Updates nsp={nsp} type="b" comic={theComic.id} slug={slug} count="1" titleTag={true} context="edit" fields={['h2', 'pubtime', 'text', 'comments']} />
			
		</>
	)
}

export default BlogPost
