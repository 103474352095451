import {useState, useEffect} from 'react'
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function LogOut({nsp}) {
	const {changeUser, site} = nsp;
	const [returnMessage, setReturnMessage] = useState(<div className="loading"><Icon icon={faSpinner} /></div>);

	useEffect(() => {
		const logOut = async () => {
			try {
				const res = await fetch(site.api + 'log-out/', {
					method: 'POST',
					credentials: 'include',
				});
				const jsonData = await res.json();
				if (jsonData.error) {
					// User not logged out on server
				}
				else {
					// Log user out from frontend
					const emptyObject = {};
					changeUser(emptyObject);

					// Send user to frontpage
					setReturnMessage(() => <Redirect push to="/" />)
				}
			}
			catch(e) {
				// User not logged out on server
				setReturnMessage(() => "Det oppstod ein teknisk feil. Vi klarte ikkje å logge deg ut.");
				console.log(e);
			}
		}
		logOut();
	}, [changeUser])

	return (
		<div>
			<h2>Logger ut ...</h2>
			{returnMessage}

		</div>
	)
}

export default LogOut
