import {useEffect} from 'react'
import {Helmet} from 'react-helmet';
import Header from './Header';
import MainMenu from './MainMenu';
import Main from './Main';
import PageFooter from '../components/PageFooter';

function Wrapper({nsp, pageHasSearchbar}) {
	const {t, mainDesignLoaded, setMainDesignLoaded} = nsp;

	useEffect(() => {
		if (!mainDesignLoaded) {
			setMainDesignLoaded(true);
		}
	}, []);

	return (
		<div className="nettserier-wrapper">
			<Helmet>
				<title>{t['PAGE_TITLE']}</title>
			</Helmet>
			<Header nsp={nsp} pageHasSearchbar={pageHasSearchbar} />
			<MainMenu nsp={nsp} />
			<Main nsp={nsp} />
			<PageFooter nsp={nsp} />
		</div>
	)
}

export default Wrapper
