import {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Comics from '../components/Comics';

function Search({nsp}) {

	const {t} = nsp;
	const getSearchParam = () => {
		const results = /[\?&]q=([^&#]*)/.exec(window.location.href);
		if (results == null) {
		   return '';
		}
	   return decodeURI(results[1]);
	}
	const [searchText, setSearchText] = useState(getSearchParam());
	const [submitText, setSubmitText] = useState(getSearchParam());
	const history = useHistory();

	const submitSearch = (el) => {
		el.preventDefault();
		setSubmitText(searchText);
		history.push('/n/' + t['/SEARCH'] + '/?q=' + encodeURIComponent(searchText));
	}

	const searchResult = <Comics nsp={nsp} q={submitText} count="24" showPagination={true} ifEmpty={<p>{t['NO_COMICS_MATCH_SEARCH_CRITERIA']}</p>} />;
	return (
		<div>
			<p className="breadcrumbs"><Link to="/">{t['PAGE_TITLE']}</Link>{" > "}</p>
			<h2>{t['SEARCH']}</h2>

			<section className="searchbox visible">
			<form onSubmit={(el) => submitSearch(el)} className="search-page-form">
				<input name="q" type="text" value={searchText} onChange={(el) => setSearchText(el.target.value)} className="soketekst" placeholder={t['SEARCH_FOR_A_COMIC']} />
				<button className="button">{t['SEARCH']}</button>
			</form>
			</section>

			<section className="front-page-comic-grid">
			{submitText ? searchResult : ''}
			</section>	
		</div>
	)
}

export default Search
