import { useState } from 'react';
import { Helmet } from 'react-helmet';
import {Link} from 'react-router-dom';
import Loading from '../../../components/Loading';

function MyComics({nsp}) {
	const {t, user, changeUser, site} = nsp;
	const [isSendingData, setIsSendingData] = useState(false);

	let confirm = async (id) => {
		setIsSendingData(true);
		let data = {
			action: 'confirm',
		}
		const res = await fetch(`${site.api}comics/${id}/creators/`, {
			method: 'PATCH',
			body: JSON.stringify(data),
			mode: 'cors',
			credentials: 'include',
			headers: {'Content-Type':'application/json', 'Accept': 'application/json'},
		})
		const resJson = await res.json();
		if (resJson.error) {
			// Error handling
		}
		else {
			let newComics = user.comics.map(el => {
				if (el.id == id) {
					let comicObject = {...el};
					comicObject.confirmed = 1;
					return comicObject;
				}
				return el;
			});
			let newUserObject = {...user, comics: newComics};
			changeUser(newUserObject);
		}
		setIsSendingData(false);
	}

	let userComics = [];
	if (user && user.comics && Array.isArray(user.comics)) {
		userComics = user.comics.map((c) => {
			return (
				<article key={c.url} className="settings-single-comic">
					<h3>{c.name}</h3>
					<nav className="settings-comic-links" aria-label={c.name}>
					<p><Link to={`/${c.url}/`} className="button">{t['SHOW_COMIC']}</Link></p>
					{
						c.confirmed ? <p><Link to={t['LINK_DASHBOARD_COMIC'].replace('{comic}', c.id)} className="button">{t['DASHBOARD']}</Link></p> : <p>{isSendingData ? <Loading tag="span" /> : <button className="button" onClick={() => confirm(c.id)}>{t['CONFIRM_ROLE']}</button>}</p>
					}
					</nav>
				</article>
			);
		})
	}
	return (
		<div>
			<Helmet>
				<title>{t['MY_COMICS']} :: {t['PAGE_TITLE']}</title>
			</Helmet>
			<nav className="breadcrumbs" aria-label={t['BREADCRUMBS']}>
				<Link to="/">{t['PAGE_TITLE']}</Link>
				{" > "}
				<Link to={t['LINK_MY_PAGE']}>{t['MY_PAGE']}</Link>
				{" > "}
			</nav>
			<h2>{t['MY_COMICS']}</h2>
			<p><Link to={t['LINK_MY_COMICS_NEW']} className="button">{t['CREATE_NEW_COMIC']}</Link></p>
			{userComics}
		</div>
	)
}

export default MyComics;